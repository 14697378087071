import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';
import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg';
import { ReactComponent as AdoptionsTR } from '../../assets/images/Adoptions_tr.svg'; // Top Right
// import { ReactComponent as AdoptionsBL } from '../../assets/images/Adoptions_bl.svg'; // Bottom Left
import { ReactComponent as AdoptionsBR } from '../../assets/images/Adoptions_br.svg'; //Bottom Right
import AdoptionsList from '../components/Adoptions/AdoptionsList';
import Pagination from '../../shared/components/ui/Pagination';
import Amount from '../../shared/components/filters/Amount';
import '../../shared/css/UnderlineTitle.css';

const Adoptions = props => {
    const { indexAdoption, adoptions } = props;

    const [limit, setLimit] = useState(6);

    useEffect(() => {
        indexAdoption(1, limit);
    }, [indexAdoption, limit]);

    const limitHandler = limit => {
        setLimit(limit);
    };

    return (
        <section className="p-8 flex w-full lg:min-h-screen justify-center relative">
            <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto" />
            <AdoptionsTR className="hidden lg:flex absolute top-0 right-0 max-w-sm xl:max-w-max" />
            {/* <AdoptionsBL className="hidden lg:flex absolute bottom-0 left-0 " /> */}
            <AdoptionsBR className="hidden lg:flex absolute bottom-32 right-0" />
            <div className="h-full flex flex-col w-full max-w-screen-content z-10 mt-20 lg:mt-32">
                <div className="space-y-2 md:space-y-4 mb-8">
                    <div className="flex justify-between items-start relative pl-4">
                        <h5 className="title text-4xl font-medium text-gray-800 lg:text-5xl title-underline-left">
                            Adopcje
                        </h5>
                        <Amount limitHandler={limitHandler} />
                    </div>
                    <p className="pl-4 text-lg leading-7 text-gray-500">
                        Z dumą chcielibyśmy Państwu przedstawić naszych
                        podopiecznych, którzy odnaleźli nowych przyjaciół i
                        otrzymali kochający dom
                    </p>
                </div>
                {adoptions.adoptions && (
                    <AdoptionsList adoptions={adoptions.adoptions} />
                )}
                {adoptions && (
                    <Pagination
                        name="adopcji"
                        totalItems={adoptions.totalItems}
                        prev={adoptions.previous}
                        next={adoptions.next}
                        index={indexAdoption}
                        range={adoptions.range}
                    />
                )}
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return { adoptions: state.adoptions.adoptions };
};

const mapDispatchToProps = dispatch => {
    return {
        indexAdoption: (page, limit) =>
            dispatch(actions.paginatedIndexAdoptionAsync(page, limit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Adoptions);
