import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    errors: [],
    loading: false,
    message: null,
    dogs: [],
    dog: null,
    counter: null,
};

const startDog = (state, action) => {
    return updateObject(state, {
        errors: null,
        loading: true,
        message: null,
    });
};

const failDog = (state, action) => {
    return updateObject(state, { errors: action.errors, loading: false });
};

const successDog = (state, action) => {
    return updateObject(state, {
        message: action.message,
        errors: null,
        loading: false,
    });
};

const clearDog = (state, action) => {
    return updateObject(state, {
        dog: null,
    });
};

const indexDog = (state, action) => {
    return updateObject(state, {
        dogs: action.dogs,
        errors: null,
        loading: false,
    });
};

const showDog = (state, action) => {
    return updateObject(state, {
        dog: action.dog,
        errors: null,
        loading: false,
    });
};

const destroyDog = (state, action) => {
    return updateObject(state, {
        dogs: state.dogs.filter(dog => dog._id !== action.dogId),
    });
};

const counterDog = (state, action) => {
    return updateObject(state, {
        counter: action.amount,
    })
}

const clearMessageErrorsDog = (state, action) => {
    return updateObject(state, {
        errors: null,
        message: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.START_DOG:
            return startDog(state, action);
        case actionsTypes.FAIL_DOG:
            return failDog(state, action);
        case actionsTypes.SUCCESS_DOG:
            return successDog(state, action);
        case actionsTypes.CLEAR_DOG:
            return clearDog(state, action);
        case actionsTypes.INDEX_DOG:
            return indexDog(state, action);
        case actionsTypes.SHOW_DOG:
            return showDog(state, action);
        case actionsTypes.DESTROY_DOG:
            return destroyDog(state, action);
        case actionsTypes.COUNTER_DOG:
            return counterDog(state, action);    
        case actionsTypes.CLEAR_MESSAGE_ERRORS_DOG:
            return clearMessageErrorsDog(state, action);
        default:
            return state;
    }
};

export default reducer;
