import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { IconCalendar } from '@tabler/icons'
import ReactHtmlParser from 'react-html-parser';
import { dateFormat } from '../../shared/utils/dateFormat';

import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg';
import * as actions from '../../store/actions/index';

const ArticleShow = props => {
    const articleId = useParams().articleId;
    const { article, showArticle, clearArticle } = props;

    const dateHandler = () => {
        const setDate = new Date(article.createdAt);
        const formattedDate = dateFormat(setDate);
        return formattedDate;
    };

    useEffect(() => {
        showArticle(articleId);
    }, [articleId, showArticle]);

    useEffect(
        () => () => {
            clearArticle();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <section className="p-8 flex w-full lg:min-h-screen justify-center relative lg:p-0">
            <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto" />
            {article && (
                <div className="h-full flex flex-col w-full max-w-screen-md z-10 mt-32 relative">
                    <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl mb-6">
                        {article.title}
                    </h1>
                    <span className="flex items-center font-semibold text-lg title-font text-gray-500 mb-6">
                        <IconCalendar size={24} stroke={1.5} className="text-gray-500 mr-2" />
                        {dateHandler()}
                    </span>
                    <div className="h-4/6 rounded-lg mb-6">
                        <img
                            className="w-full h-4/6 rounded-lg object-cover"
                            src={process.env.REACT_APP_API_URL + article.image}
                            alt={`Zdjęcie dla artykułu ${article.title}`}
                        />
                    </div>
                    <div className="mb-8 lg:mb-28 text-justify">
                        {ReactHtmlParser(article.content)}
                    </div>
                </div>
            )}
        </section>
    );
};

const mapStateToProps = state => {
    return {
        article: state.articles.article,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showArticle: articleId => dispatch(actions.showArticleAsync(articleId)),
        clearArticle: () => dispatch(actions.clearArticle()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleShow);
