import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    token: null || localStorage.getItem('token'),
    userId: null,
    errors: [],
    expiresIn: null,
    loading: false,
    isAuth: false || !!localStorage.getItem('token'),
};

const authStart = (state, action) => {
    return updateObject(state, { errors: null, loading: true, isAuth: false });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        userId: action.userId,
        expiresIn: action.expiresIn,
        errors: null,
        loading: false,
        isAuth: true,
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        errors: action.errors,
        loading: false,
        isAuth: false,
    });
};

const logout = (state, action) => {
    return updateObject(state, { token: null, userId: null, isAuth: false });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.AUTH_START:
            return authStart(state, action);
        case actionsTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionsTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionsTypes.LOGOUT:
            return logout(state, action);
        default:
            return state;
    }
};

export default reducer;
