import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as PetsDog } from '../../../assets/images/Pets_dog.svg';
import { ReactComponent as PetsCat } from '../../../assets/images/Pets_cat.svg';
import Button from '../../../shared/components/ui/Button';
import * as actions from '../../../store/actions/index';

import '../../pages/RubikFont.css';

const PetItem = props => {
    return (
        <>
            {/* Mobile */}
            <div className="max-w-3xl h-5/6 flex flex-col rounded-lg lg:hidden">
                <div className="w-full h-5/6 lg:hidden">
                    <img
                        src={props.image}
                        alt={props.image}
                        className="w-full object-cover rounded-lg"
                    />
                </div>
                <div className="flex justify-between items-center py-8">
                    <h1 className="title text-2xl text-gray-800 font-medium">
                        {`${props.title}: ${props.counter}`}
                    </h1>
                    <Link to="/pets" className="w-2/6">
                        <Button
                            onClick={() =>
                                props.type === 'dog'
                                    ? props.isDogs(true)
                                    : props.isDogs(false)
                            }
                            element="lp-filled-purple"
                            className="relative py-4 w-full"
                        >
                            <span className="w-full">Więcej</span>
                        </Button>
                    </Link>
                </div>
            </div>
            {/* Dekstop */}
            <div
                className={`hidden lg:flex w-full h-full flex-row-reverse rounded-lg even:ml-8 bg-white relative ${props.className}`}
            >
                <div className="hidden lg:block w-6/12 h-full">
                    <img
                        src={props.image}
                        alt={props.image}
                        className="h-full object-cover rounded-l-none rounded-r-lg"
                    />
                </div>
                <div className="hidden w-6/12 lg:flex lg:justify-center relative">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-9xl text-main-orange font-light z-10">
                            {props.counter}
                        </h1>
                        <h1 className="title text-3xl text-gray-800 font-medium z-10">
                            {props.title}
                        </h1>
                    </div>
                    {props.type === 'dog' ? (
                        <PetsDog className="absolute right-0 top-0 w-40" />
                    ) : (
                        <PetsCat className="absolute right-0 top-0 w-60" />
                    )}
                    {props.type === 'dog' ? (
                        <Link to="/pets" className="absolute bottom-0 -mb-7">
                            <Button
                                onClick={() => props.isDogs(true)}
                                element="lp-filled-orange"
                                className="px-6 py-4 relative"
                            >
                                <span>Znajdź przyjaciela</span>
                            </Button>
                        </Link>
                    ) : (
                        <Link to="/pets" className="absolute bottom-0 -mb-7">
                            <Button
                                onClick={() => props.isDogs(false)}
                                element="lp-filled-purple"
                                className="px-6 py-4 relative"
                            >
                                <span>Znajdź przyjaciela</span>
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        isDogs: isDogs => dispatch(actions.isDogs(isDogs)),
    };
};

export default connect(null, mapDispatchToProps)(PetItem);
