import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';
import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg';
import { ReactComponent as ContactTR } from '../../assets/images/Contact_tr.svg'; // Top Right
import { ReactComponent as ContactBL } from '../../assets/images/Contact_bl.svg'; // Bottom Left
import { ReactComponent as ContactBR } from '../../assets/images/Contact_br.svg'; //Bottom Right
import PetsList from '../components/Pets/PetsList';
import Button from '../../shared/components/ui/Button';
import '../../shared/css/UnderlineTitle.css';

const Pets = props => {
    const { indexDog, indexCat, dogs, cats, isDogs } = props;

    useEffect(() => {
        if (isDogs === true) {
            indexDog();
        } else {
            indexCat();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDogs]);

    return (
        <section className="p-8 flex w-full lg:min-h-screen justify-center relative">
            <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto z-10" />
            <ContactTR className="hidden lg:flex absolute top-0 right-0 max-w-sm xl:max-w-max" />
            <ContactBL className={`hidden lg:flex absolute bottom-0 left-0`} />
            <ContactBR className="hidden lg:flex absolute bottom-32 right-0" />
            <div className="h-full flex flex-col w-full max-w-screen-content z-10 mt-20 lg:mt-32">
                <div className="space-y-2 md:space-y-4 mb-8">
                    <div className="flex justify-between relative pl-4">
                        <h5 className="title text-4xl font-medium text-gray-800 lg:text-5xl title-underline-left">
                            {`Nasze ${isDogs ? 'Psy' : 'Koty'}`}
                        </h5>
                        <div className="space-x-2">
                            <Button
                                onClick={() => props.togglePets(!isDogs)}
                                element="switch-pet"
                                className="px-2 py-2 md:px-6 md:py-4 relative"
                            >
                                {isDogs === true
                                    ? 'Wyświetl Koty'
                                    : 'Wyświetl Psy'}
                            </Button>
                        </div>
                    </div>
                    <p className="text-lg leading-7 text-gray-500 pl-4">
                        Przyjedź do nas i adoptuj.
                    </p>
                </div>
                <PetsList
                    isDogs={isDogs}
                    pets={isDogs === true ? dogs : cats}
                />
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        dogs: state.dogs.dogs,
        cats: state.cats.cats,
        isDogs: state.others.isDogs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        togglePets: isDogs => dispatch(actions.isDogs(isDogs)),
        indexDog: () => dispatch(actions.indexDogAsync()),
        indexCat: () => dispatch(actions.indexCatAsync()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pets);
