import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';

import Button from '../../../shared/components/ui/Button';

const PetItem = props => {
    let { path } = useRouteMatch();

    return (
        <div className="flex flex-col h-full mb-8">
            <Link
                to={`${path}/${props.id}`}
                className="rounded-lg h-80 cursor-pointer overflow-hidden"
            >
                <img
                    className="w-full h-full object-cover rounded-lg transform hover:scale-110 transition-transform duration-300 ease-in-out"
                    src={process.env.REACT_APP_API_URL + props.image}
                    alt={`Zdjęcie przedstawia ${props.name}. Ma ${props.age}`}
                />
            </Link>
            <div className="flex justify-between items-center mt-4">
                <div className="flex flex-col">
                    <h5 className="title font-medium text-2xl">{props.name}</h5>
                    <div className="flex items-center">
                        <span className="title font-light text-lg">
                            {props.sex} / {props.age}
                        </span>
                    </div>
                </div>
                <Link to={`${path}/${props.id}`} className="relative">
                    <Button
                        element={
                            props.index % 2 === 1
                                ? 'lp-filled-purple'
                                : 'lp-filled-orange'
                        }
                        className="relative p-4"
                    >
                        <span className="w-full">Czytaj więcej</span>
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default PetItem;
