import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    mode: null || localStorage.getItem('theme'),
}

const storeMode = (state, action) => {
    return updateObject(state, {
        mode: action.mode
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.STORE_MODE:
            return storeMode(state, action);
        default:
            return state;    
    }
}

export default reducer;