import React from 'react';

const AdoptionItem = props => {
    return (
        <div className="flex flex-col h-full mb-8">
            <div className="rounded-lg h-80">
                <img
                    className="w-full h-full object-cover rounded-lg"
                    src={process.env.REACT_APP_API_URL + props.image}
                    alt={`Zdjęcie przedstawia ${props.name} i ${props.owner}`}
                />
            </div>
            <div className="flex justify-between items-center mt-4">
                <div className="flex flex-col">
                    <h5 className="title font-medium text-2xl">{`${props.name} & ${props.owner}`}</h5>
                    <div className="flex items-center">
                        <span className="title font-light text-lg">
                            {`${props.sex} / ${props.age}`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdoptionItem;
