import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ArticleItem from './ArticleItem';
import SpinnerPaw from '../../../shared/components/ui/SpinnerPaw';
import { dateFormat } from '../../../shared/utils/dateFormat';

const ArticlesList = props => {
    const { articles, loading } = props;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoading !== loading) {
                setIsLoading(loading);
            }
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [isLoading, loading]);

    return (
        <>
            {isLoading && <SpinnerPaw className="absolute" loading={isLoading} />}
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full mb-8">
                {articles.map(article => {
                    const setDate = new Date(article.createdAt);
                    const date = dateFormat(setDate);
                    return (
                        <ArticleItem
                            key={article._id}
                            id={article._id}
                            image={article.image}
                            title={article.title}
                            content={article.content}
                            date={date}
                        />
                    );
                })}
            </section>
        </>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.articles.loading,
    };
};

export default connect(mapStateToProps)(ArticlesList);
