import React, { useRef, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ArticleItem from './ArticleItem';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { dateFormat } from '../../../shared/utils/dateFormat';
import './Slider.css';

const ArticlesList = props => {
    const desktopWrapper = useRef(null);
    const mobileWrapper = useRef(null);
    const { articles, articlesWrapperHandler } = props;

    useEffect(() => {
        const desktopWrapperElements = desktopWrapper.current;
        const mobileWrapperElements = mobileWrapper.current;
        articlesWrapperHandler({
            desktop: desktopWrapperElements,
            mobile: mobileWrapperElements,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        articles && (
            <>
                <div
                    ref={mobileWrapper}
                    className="lg:hidden w-full h-full mb-2 rounded-lg"
                >
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        emulateTouch={true}
                        className="rounded-lg max-w-3xl z-10 h-full"
                    >
                        {articles.map((article, index) => {
                            const setDate = new Date(article.createdAt);
                            const date = dateFormat(setDate);
                            return (
                                <ArticleItem
                                    key={article._id}
                                    id={article._id}
                                    title={article.title}
                                    image={article.image}
                                    content={article.content}
                                    date={date}
                                    index={index}
                                />
                            );
                        })}
                    </Carousel>
                </div>
                <div
                    ref={desktopWrapper}
                    className="hidden lg:flex items-center justify-between w-full h-full mb-28 px-6"
                >
                    {articles.map((article, index) => {
                        const setDate = new Date(article.createdAt);
                        const date = dateFormat(setDate);
                        return (
                            <ArticleItem
                                key={article._id}
                                id={article._id}
                                title={article.title}
                                image={article.image}
                                content={article.content}
                                date={date}
                                index={index}
                            />
                        );
                    })}
                </div>
            </>
        )
    );
};

export default ArticlesList;
