import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { IconCalendar } from '@tabler/icons'

import './InterFont.css';
import './ArticleItem.css';

const ArticleItem = props => {
    let { path } = useRouteMatch();

    return (
        <div className="flex flex-col justify-start rounded-lg transition duration-200 ease-in-out mb-8">
            <Link
                to={`${path}/${props.id}`}
                className="rounded-lg cursor-pointer overflow-hidden"
            >
                <img
                    className="w-full h-full max-h-96 object-cover rounded-lg transform hover:scale-110 transition-transform duration-300 ease-in-out"
                    src={process.env.REACT_APP_API_URL + props.image}
                    alt={`Zdjęcie dla artykułu ${props.title}`}
                />
            </Link>
            <span className="flex items-center font-semibold text-lg title-font text-gray-500 mt-6 mb-2">
                <IconCalendar size={24} stroke={1.5} className="text-gray-500 mr-2" />
                {props.date}
            </span>
            <div className="md:flex-grow">
                <Link to={`${path}/${props.id}`} className="cursor-pointer">
                    <h2 className="title text-3xl font-semibold text-gray-900 title-font mb-2 cursor-pointer">
                        {props.title}
                    </h2>
                </Link>

                <Link to={`${path}/${props.id}`} className="article-link title">
                    Czytaj Więcej
                </Link>
            </div>
        </div>
    );
};

export default ArticleItem;
