import React from 'react'

import './Spinner.css';

const Spinner = () => {
    return (
        <div className="loader">
            Loading...         
        </div>
    )
}

export default Spinner
