import * as actionTypes from './action_types';
import axios from 'axios';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
    };
};

export const authSuccess = (token, userId, expiresIn) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token,
        userId,
        expiresIn,
    };
};

export const authFail = errors => {
    return {
        type: actionTypes.AUTH_FAIL,
        errors: errors,
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: actionTypes.LOGOUT,
    };
};

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const authStartAsync = (email, password, history) => {
    return async dispatch => {
        try {
            dispatch(authStart());
            const authData = {
                email: email,
                password: password,
            };
            const response = await axios.post('/api/user/login', authData);
            const expirationDate = new Date(
                new Date().getTime() + response.data.expiresIn * 1000
            );
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('userId', response.data.userId);
            dispatch(
                authSuccess(
                    response.data.token,
                    response.data.userId,
                    response.data.expiresIn
                )
            );
            dispatch(checkAuthTimeout(response.data.expiresIn));
        } catch (error) {
            console.log(error);
            // console.log(error.response.data.errors[0].message);
            // if (error.response.data.errors) {
            //     dispatch(authFail(error.response.data.errors));
            // }
        }
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(
                localStorage.getItem('expirationDate')
            );
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(
                    checkAuthTimeout(
                        (expirationDate.getTime() - new Date().getTime()) / 1000
                    )
                );
            }
        }
    };
};
