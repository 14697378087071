import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import PetsList from '../components/Pets/PetsList';
import Pets_Dog from '../../assets/images/Pets_Dog.jpg';
import Pets_Cat from '../../assets/images/Pets_Cat.jpg';
import * as actions from '../../store/actions/index';

gsap.registerPlugin(ScrollTrigger);

const Pets = props => {
    const [ petsListRefData, setPetsListRefData ] = useState(null);
    const wrapper = useRef(null);
    const cats = { type: 'cat', image: Pets_Cat };
    const dogs = { type: 'dog', image: Pets_Dog };

    const { counterDog, counterCat, dogsAmount, catsAmount } = props;

    useEffect(() => {
        counterCat();
        counterDog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

   
    useEffect(() => {
        if (petsListRefData) {
            const wrapperElements = wrapper.current.children;

            const [ firstTitle, secondTitle, paragraph ] = wrapperElements;

            gsap.set([firstTitle, secondTitle, paragraph, petsListRefData], { autoAlpha: 0 });

            const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

            tl.fromTo(firstTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel')
            .fromTo(secondTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.2')
            .fromTo(paragraph, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.4')
            .fromTo(petsListRefData, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.6')

            ScrollTrigger.create({
                trigger: wrapperElements,
                animation: tl,
                start: 'top 50%',
            })
        }
    }, [petsListRefData]);

    const petsWrapperHandler = refData => {
        setPetsListRefData(refData);
    }

    return (
        <section className="flex w-full min-h-screen justify-start lg:justify-center bg-third-gray relative">
            <div
                ref={wrapper}
                className="h-full p-8 flex flex-col items-start lg:items-center lg:justify-between w-full max-w-screen-content z-10"
            >
                <h1 className="title text-2xl font-medium text-main-orange uppercase mt-8 lg:mt-36 pb-4">
                    Nasze Zwierzęta
                </h1>
                <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl pb-6">
                    Szukacie przyjaciela?
                </h1>
                <p className="text-base lg:text-center leading-7 lg:leading-8 lg:w-2/5 pb-12">
                    Zobaczcie psiaki i kociaki, które teraz szukają domu! Jeżeli
                    chciałbyś zagwarantować, któremuś zwierzęciu kochający dom –
                    nie wahaj się! Przyjedź do nas i adoptuj.
                </p>
                {dogsAmount !== null && catsAmount !== null && (
                    <PetsList
                        pets={[
                            {
                                ...dogs,
                                counter: dogsAmount,
                                title:
                                    dogsAmount === 0
                                        ? 'Brak psów'
                                        : dogsAmount === 1
                                        ? 'Oczekujący pies'
                                        : dogsAmount > 1 && dogsAmount < 5
                                        ? 'Oczekujące psy'
                                        : 'Oczekujących psów',
                            },
                            {
                                ...cats,
                                counter: catsAmount,
                                title:
                                    catsAmount === 0
                                        ? 'Brak kotów'
                                        : catsAmount === 1
                                        ? 'Oczekujący kot'
                                        : catsAmount > 1 && catsAmount < 5
                                        ? 'Oczekujące koty'
                                        : 'Oczekujących kotów',
                            },
                        ]}
                        petsWrapperHandler={petsWrapperHandler}
                    />
                )}
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        dogsAmount: state.dogs.counter,
        catsAmount: state.cats.counter,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        counterDog: () => dispatch(actions.counterDogAsync()),
        counterCat: () => dispatch(actions.counterCatAsync()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pets);
