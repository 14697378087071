import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import './assets/tailwind/tailwind.css';

import authReducer from './store/reducers/auth';
import dogsReducer from './store/reducers/dogs';
import catsReducer from './store/reducers/cats';
import articlesReducer from './store/reducers/articles';
import adoptionsReducer from './store/reducers/adoptions';
import darkModeReducer from './store/reducers/darkMode';
import mailReducer from './store/reducers/mail';
import othersReducer from './store/reducers/others';
import App from './App';
import axios from 'axios';

// axios.defaults.baseURL = 'http://localhost:4000/';
axios.defaults.baseURL = 'https://api.tozjawor.pl/';

const rootReducer = combineReducers({
    auth: authReducer,
    mail: mailReducer,
    dogs: dogsReducer,
    cats: catsReducer,
    articles: articlesReducer,
    adoptions: adoptionsReducer,
    darkMode: darkModeReducer,
    others: othersReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);
