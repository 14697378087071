import React from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';

const Amount = props => {
    const { limitHandler } = props;

    return (
        <div className="relative flex items-center justify-center max-w-xs ml-4">
            <label htmlFor="amount" className="mr-2">
                Ilość:
            </label>
            <select
                onChange={(event) => limitHandler(event.target.value)}
                as="select"
                name="amount"
                className={`text-gray-700 dark:text-white cursor-pointer focus:outline-none 
                    focus:border-indigo-300 border border-gray-200 dark:border-gray-900 rounded py-2 
                    px-6 block w-full appearance-none dark:bg-gray-700`}
            >
                <option value={6}>6</option>
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={48}>48</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-white">
                <RiArrowDropDownLine size={16} />
            </div>
        </div>
    );
};

export default Amount;
