import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import About from '../../assets/images/AboutUs@2x.png';
import AboutMobile from '../../assets/images/AboutMobile.png';
import './AboutUs.css';

gsap.registerPlugin(ScrollTrigger);

const AboutUs = () => {
    const titlesWrapper = useRef(null);
    const paragraphsWrapper = useRef(null);

    useEffect(() => {
        const titlesElements = titlesWrapper.current.children;
        const titlesLine = titlesWrapper.current;
        const paragraphsElements = paragraphsWrapper.current.children;

        gsap.set([titlesLine, ...titlesElements, ...paragraphsElements], { autoAlpha: 0 });
        gsap.set(titlesLine, { transformOrigin: '50% 0%'})

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.fromTo(titlesLine, { y: '-=50', scaleY: 0 }, { duration: 1.5, y: '+=50', scaleY: 1, autoAlpha: 1 }, 'titleLabel')
        .fromTo(titlesElements, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 }, 'titleLabel+=0.2')
        .fromTo(paragraphsElements, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1, stagger: 0.2 }, 'titleLabel+=0.4')

        ScrollTrigger.create({
            trigger: titlesElements,
            animation: tl,
            start: 'top 50%',
        })

    }, [])

    return (
        <section id="AboutUs" className="flex w-full h-screen justify-center relative">
            <img
                src={About}
                alt=""
                className="hidden lg:flex w-full h-full object-cover"
            />
            <img
                src={AboutMobile}
                alt=""
                className="lg:hidden w-full h-full object-center object-cover image-brightness"
            />
            <div className="flex flex-col p-8 items-start lg:justify-between w-full h-full max-w-screen-content z-10 overflow-hidden absolute">
                <div ref={titlesWrapper} className="flex flex-col border-l-4 border-main-orange mt-8 lg:mt-36 pl-4">
                    <h5 className="title text-main-orange text-2xl font-medium uppercase">
                        Poznaj nas bliżej
                    </h5>
                    <h2 className="title text-white text-4xl lg:text-6xl font-medium">
                        O nas
                    </h2>
                </div>
                <div ref={paragraphsWrapper} className="flex flex-col justify-around lg:justify-start w-full h-full lg:w-3/6 mt-4 lg:mt-12">
                    <p className="text-white text-sm lg:text-base font-medium mb-2 lg:mb-12 text-justify">
                        Pomagamy zwierzętom, tym chorym, opuszczonym, głodnym.
                        Tym, które tej pomocy oczekują.
                    </p>
                    <p className="text-white text-sm lg:text-base font-medium mb-2 lg:mb-12 text-justify">
                        Codziennie sprawujemy opiekę nad naszymi podopiecznymi z
                        przytuliska, nie zważając na warunki atmosferyczne.
                        Dbamy o czystość miejsc, w których przebywają,
                        socjalizujemy nasze zwierzęta.
                    </p>
                    <p className="text-white text-sm lg:text-base font-medium mb-2 lg:mb-12 text-justify">
                        Interweniujemy w sytuacjach, gdzie dzieje się krzywda
                        zwierzętom. W trakcie działalności TOZ Jawor - nasz
                        oddział został utworzony w 2008 roku – inspektorzy
                        przeprowadzili ponad 2000 interwencji w przypadkach
                        znęcania się nad zwierzętami. Interwencje często
                        odbywają się przy udziale lokalnej Policji.
                    </p>
                    <p className="text-white text-sm lg:text-base font-medium text-justify">
                        Staramy się pozyskiwać fundusze w celu niesienia pomocy
                        naszym zwierzętom, szukamy sponsorów dla organizacji
                        oraz organizujemy charytatywne wydarzenia (eventy).
                    </p>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
