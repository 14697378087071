import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';
import ArticlesList from '../components/Articles/ArticlesList';
import Pagination from '../../shared/components/ui/Pagination';
import Amount from '../../shared/components/filters/Amount';
import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg';
import { ReactComponent as ArticlesTR } from '../../assets/images/Articles_tr.svg'; // Top Right
import { ReactComponent as ArticlesBL } from '../../assets/images/Articles_bl.svg'; // Bottom Left
import { ReactComponent as ArticlesBR } from '../../assets/images/Articles_br.svg'; //Bottom Right
import '../../shared/css/UnderlineTitle.css';

const ArticlesSubpage = props => {
    const { indexArticle, articles } = props;

    const [limit, setLimit] = useState(6);

    useEffect(() => {
        indexArticle(1, limit);
    }, [indexArticle, limit]);

    const limitHandler = limit => {
        setLimit(limit);
    };

    return (
        <section className="p-8 flex w-full lg:min-h-screen justify-center relative">
            <ArticlesTR className="hidden lg:flex absolute top-0 right-0 max-w-sm xl:max-w-max" />
            <ArticlesBL className="hidden lg:flex absolute bottom-0 left-0 " />
            <ArticlesBR className="hidden lg:flex absolute bottom-32 right-0" />
            <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto" />
            <div className="h-full flex flex-col w-full max-w-screen-content z-10 mt-20 lg:mt-32">
                <div className="space-y-2 md:space-y-4 mb-8">
                    <div className="flex justify-between items-start relative pl-4">
                        <h5 className="title text-4xl font-medium text-gray-800 lg:text-5xl title-underline-left">
                            Aktualności
                        </h5>
                        <Amount limitHandler={limitHandler} />
                    </div>
                    <p className="pl-4 text-lg leading-7 text-gray-500">
                        Wszystkie najnowsze wiadomości prosto od ekipy Toz
                        Jawor!
                    </p>
                </div>
                {articles.articles && (
                    <ArticlesList articles={articles.articles} />
                )}
                {articles && (
                    <Pagination
                        name="artykułów"
                        totalItems={articles.totalItems}
                        prev={articles.previous}
                        next={articles.next}
                        index={indexArticle}
                        range={articles.range}
                    />
                )}
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        articles: state.articles.articles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        indexArticle: (page, limit) =>
            dispatch(actions.paginatedIndexArticleAsync(page, limit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesSubpage);
