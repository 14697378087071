import * as actionTypes from './action_types';
import axios from 'axios';

export const startMail = () => {
    return {
        type: actionTypes.START_MAIL,
    };
};

export const successMail = message => {
    return {
        type: actionTypes.SUCCESS_MAIL,
        message,
    };
};

export const sendMailAsync = formData => {
    return async dispatch => {
        try {
            dispatch(startMail());
            const response = await axios.post('/api/mail', formData, {
                headers: {
                    Accept: 'application/json',
                },
            });
            dispatch(successMail(response.data.message));
        } catch (error) {
            console.log(error);
        }
    };
};
