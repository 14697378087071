import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { ReactComponent as Tax } from '../../../assets/images/Tax.svg';
import { ReactComponent as Transfer } from '../../../assets/images/Transfer.svg';
import { ReactComponent as Items } from '../../../assets/images/Items.svg';
import { ReactComponent as Volunteering } from '../../../assets/images/Volunteering.svg';

gsap.registerPlugin(ScrollTrigger);

const HelpItems = () => {
    const wrapper = useRef(null);
    const desktopTaxWrapper = useRef(null);
    const desktopTransferWrapper = useRef(null);
    const desktopItemsWrapper = useRef(null);
    const desktopVolunteeringWrapper = useRef(null);

    const mobileTaxTitleWrapper = useRef(null);
    const mobileTransferTitleWrapper = useRef(null);
    const mobileItemsTitleWrapper = useRef(null);
    const mobileVolunteeringTitleWrapper = useRef(null);
    const mobileTaxWrapper = useRef(null);
    const mobileTransferWrapper = useRef(null);
    const mobileItemsWrapper = useRef(null);
    const mobileVolunteeringWrapper = useRef(null);
    
    useEffect(() => {
        const [ taxElements ] = desktopTaxWrapper.current.children;;
        const wrapperElements = wrapper.current.children;
       
        const divWrapper = wrapperElements[0];

        const background = taxElements.getElementById('Background');
        const leftPlant = taxElements.getElementById('LeftPlant');
        const rightPlant = taxElements.getElementById('RightPlant');
        const woman = taxElements.getElementById('Woman');
        const circle = taxElements.getElementById('Circle');
        const dog = taxElements.getElementById('Dog');
        const leftPlantLeaves = taxElements.getElementById('Leaves_2');

        gsap.set([ divWrapper, background, leftPlant, rightPlant, woman, circle, dog, ...leftPlantLeaves.children ], { autoAlpha: 0 });
        gsap.set(circle, { transformOrigin: 'center' });

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.fromTo(divWrapper, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 })
            .to(background, { duration: 0.5, autoAlpha: 1 }, 'backgroundLabel-=1.5')
            .to(rightPlant, { duration: 0.3, autoAlpha: 1 }, 'backgroundLabel')
            .to(leftPlant, { duration: 0.3, autoAlpha: 1 }, 'backgroundLabel')
            .to(leftPlantLeaves.children, { autoAlpha: 1, stagger: 0.3 }, 'backgroundLabel')
            .fromTo(dog, { x: '+=100' }, { duration: 1, x: '-=100', autoAlpha: 1 }, '-=4.4')
            .fromTo(woman, { x: '-=100' }, { duration: 1, x: '+=100', autoAlpha: 1 }, '-=4.4')
            .fromTo(circle, { scale: 0 }, { duration: 0.3, scale: 1, autoAlpha: 1 }, '-=3.8');

        ScrollTrigger.create({
            trigger: taxElements,
            animation: tl,
            start: 'top 70%',
        });
    }, []);

    useEffect(() => {
        const [ transferElements ] = desktopTransferWrapper.current.children;
        const wrapperElements = wrapper.current.children;
       
        const divWrapper = wrapperElements[1];

        const background = transferElements.getElementById('Background');
        const card = transferElements.getElementById('Card');
        const woman = transferElements.getElementById('Woman');
        const [ cardBackground, cardDarkStrip, cardLightStrip, cardCircle ] = [ ...card.children ];

        gsap.set([ divWrapper, background, cardBackground, cardDarkStrip, cardLightStrip, cardCircle, woman ], { autoAlpha: 0 });
        gsap.set(cardDarkStrip, { transformOrigin: '0% 50%' });
        gsap.set(cardLightStrip, { transformOrigin: '100% 50%'})
        gsap.set(cardCircle, { transformOrigin: '50% 50%' });

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.fromTo(divWrapper, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 })
          .to(background, { duration: 0.5, autoAlpha: 1 }, '-=1.5')
          .to(cardBackground, { duration: 0.5, autoAlpha: 1 }, 'startLabel')
          .fromTo(woman, { x: '-=100' }, { duration: 1, x: '+=100' , autoAlpha: 1}, 'startLabel')
          .fromTo(cardDarkStrip, { scaleX: 0 }, { duration: 1, scaleX: 1, autoAlpha: 1 }, 'cardLabel-=1')
          .fromTo(cardLightStrip, { scaleX: 0 }, { duration: 1, scaleX: 1, autoAlpha: 1 }, 'cardLabel-=1')
          .fromTo(cardCircle, { scale: 0 }, { duration: 1, scale: 1, autoAlpha: 1 }, 'cardLabel-=1')

        ScrollTrigger.create({
            trigger: transferElements,
            animation: tl,
            start: 'top 70%',
        })

    }, [])

    useEffect(() => {
        const [ itemsElements ] = desktopItemsWrapper.current.children;
        const wrapperElements = wrapper.current.children;
       
        const divWrapper = wrapperElements[2];

        const background = itemsElements.getElementById('Background');
        const food = itemsElements.getElementById('Food');
        const cat = itemsElements.getElementById('Cat');
        const toy = itemsElements.getElementById('Toy');
        const [ foodShadow, foodCrisps, foodBowl ] = [...food.children];

        gsap.set([ divWrapper, background, foodCrisps, foodBowl, foodShadow, cat, toy ], { autoAlpha: 0 });
        gsap.set(foodCrisps, { transformOrigin: '50% 100%' })

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.fromTo(divWrapper, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 })
          .to(background, { duration: 0.5, autoAlpha: 1 }, '-=1.5')
          .fromTo(cat, { x: '-=200', scale: 0.5 }, { duration: 1, x: '+=200', scale: 1, autoAlpha: 1 })
          .to(foodBowl, { duration: 1, autoAlpha: 1}, 'foodLabel')
          .to(foodShadow, { duration: 1, autoAlpha: 1}, 'foodLabel')
          .fromTo(toy, { y: '-=100' }, { duration: 1, y: '+=100', autoAlpha: 1 }, 'foodLabel')
          .fromTo(foodCrisps, { scaleY: 0 }, { duration: 1 , scaleY: 1, autoAlpha: 1}, 'foodLabel+=0.2')
        
        ScrollTrigger.create({
            trigger: itemsElements,
            animation: tl,
            start: 'top 70%',
        })

    }, [])

    useEffect(() => {
        const [ volunteeringElements ] = desktopVolunteeringWrapper.current.children;
        const wrapperElements = wrapper.current.children;
       
        const divWrapper = wrapperElements[3];

        const background = volunteeringElements.getElementById('Background');
        const firstMan = volunteeringElements.getElementById('firstMan');
        const firstWoman = volunteeringElements.getElementById('firstWoman');
        const secondMan = volunteeringElements.getElementById('secondMan');
        const secondWoman = volunteeringElements.getElementById('secondWoman');
        const dog = volunteeringElements.getElementById('Dog');
        const plant = volunteeringElements.getElementById('Plant');
        const stone = volunteeringElements.getElementById('Stone');

        gsap.set([ divWrapper, background, firstMan, firstWoman, secondMan, secondWoman, dog, plant, stone], { autoAlpha: 0 });

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.fromTo(divWrapper, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 })
          .to([background, plant, stone], { duration: 0.5, autoAlpha: 1 }, '-=1.5')
          .to(dog, { duration: 1, autoAlpha: 1 }, 'dogLabel')
          .to(firstMan, { duration: 1, autoAlpha: 1 }, 'personLabel')
          .to(firstWoman, { duration: 1, autoAlpha: 1 }, 'personLabel+=0.5')
          .to(secondWoman, { duration: 1, autoAlpha: 1 }, 'personLabel+=1')
          .to(secondMan, { duration: 1, autoAlpha: 1 }, 'personLabel+=1.5')
         

        ScrollTrigger.create({
            trigger: volunteeringElements,
            animation: tl,
            start: 'top 70%',
        })
    }, [])

    useEffect(() => {
        const [ taxElements ] = mobileTaxWrapper.current.children;
        const wrapperElements = mobileTaxTitleWrapper.current.children;

        const background = taxElements.getElementById('Background');
        const leftPlant = taxElements.getElementById('LeftPlant');
        const rightPlant = taxElements.getElementById('RightPlant');
        const woman = taxElements.getElementById('Woman');
        const circle = taxElements.getElementById('Circle');
        const dog = taxElements.getElementById('Dog');
        const leftPlantLeaves = taxElements.getElementById('Leaves_2');

        gsap.set([background, leftPlant, rightPlant, woman, circle, dog, ...leftPlantLeaves.children, ...wrapperElements], { autoAlpha: 0 });
        gsap.set(circle, { transformOrigin: 'center' });

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.to(background, { duration: 0.5, autoAlpha: 1 }, 'backgroundLabel')
            .to(rightPlant, { duration: 0.3, autoAlpha: 1 }, 'backgroundLabel')
            .to(leftPlant, { duration: 0.3, autoAlpha: 1 }, 'backgroundLabel')
            .to(leftPlantLeaves.children, { autoAlpha: 1, stagger: 0.3 }, 'backgroundLabel')
            .fromTo(dog, { x: '+=100' }, { duration: 1, x: '-=100', autoAlpha: 1 }, '-=4.4')
            .fromTo(woman, { x: '-=100' }, { duration: 1, x: '+=100', autoAlpha: 1 }, '-=4.4')
            .fromTo(circle, { scale: 0 }, { duration: 0.3, scale: 1, autoAlpha: 1 }, '-=3.8')
            .fromTo(wrapperElements, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1, stagger: 0.2 }, '-=4.4')

        ScrollTrigger.create({
            trigger: taxElements,
            animation: tl,
            start: 'top 40%',
        });
    }, []);

    useEffect(() => {
        const [ transferElements ] = mobileTransferWrapper.current.children;
        const wrapperElements = mobileTransferTitleWrapper.current.children;

        const background = transferElements.getElementById('Background');
        const card = transferElements.getElementById('Card');
        const woman = transferElements.getElementById('Woman');
        const [ cardBackground, cardDarkStrip, cardLightStrip, cardCircle ] = [ ...card.children ];

        gsap.set([ background, cardBackground, cardDarkStrip, cardLightStrip, cardCircle, woman, ...wrapperElements ], { autoAlpha: 0 });
        gsap.set(cardDarkStrip, { transformOrigin: '0% 50%' });
        gsap.set(cardLightStrip, { transformOrigin: '100% 50%'})
        gsap.set(cardCircle, { transformOrigin: '50% 50%' });

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.to(background, { duration: 0.5, autoAlpha: 1 })
          .to(cardBackground, { duration: 0.5, autoAlpha: 1 }, 'startLabel')
          .fromTo(woman, { x: '-=100' }, { duration: 1, x: '+=100' , autoAlpha: 1}, 'startLabel')
          .fromTo(cardDarkStrip, { scaleX: 0 }, { duration: 1, scaleX: 1, autoAlpha: 1 }, 'cardLabel-=1')
          .fromTo(cardLightStrip, { scaleX: 0 }, { duration: 1, scaleX: 1, autoAlpha: 1 }, 'cardLabel-=1')
          .fromTo(cardCircle, { scale: 0 }, { duration: 1, scale: 1, autoAlpha: 1 }, 'cardLabel-=1')
          .fromTo(wrapperElements, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1, stagger: 0.2 }, 'cardLabel-=2')

        ScrollTrigger.create({
            trigger: transferElements,
            animation: tl,
            start: 'top 40%',
        })

    }, [])

    useEffect(() => {
        const [ itemsElements ] = mobileItemsWrapper.current.children;
        const wrapperElements = mobileItemsTitleWrapper.current.children;

        const background = itemsElements.getElementById('Background');
        const food = itemsElements.getElementById('Food');
        const cat = itemsElements.getElementById('Cat');
        const toy = itemsElements.getElementById('Toy');
        const [ foodShadow, foodCrisps, foodBowl ] = [...food.children];

        gsap.set([ background, foodCrisps, foodBowl, foodShadow, cat, toy, ...wrapperElements ], { autoAlpha: 0 });
        gsap.set(foodCrisps, { transformOrigin: '50% 100%' })

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.to(background, { duration: 0.5, autoAlpha: 1 })
          .fromTo(cat, { x: '-=200', scale: 0.5 }, { duration: 1, x: '+=200', scale: 1, autoAlpha: 1 })
          .to(foodBowl, { duration: 1, autoAlpha: 1}, 'foodLabel')
          .to(foodShadow, { duration: 1, autoAlpha: 1}, 'foodLabel')
          .fromTo(toy, { y: '-=100' }, { duration: 1, y: '+=100', autoAlpha: 1 }, 'foodLabel')
          .fromTo(foodCrisps, { scaleY: 0 }, { duration: 1 , scaleY: 1, autoAlpha: 1}, 'foodLabel+=0.2')
          .fromTo(wrapperElements, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1, stagger: 0.2 }, '-=2')
        
        ScrollTrigger.create({
            trigger: itemsElements,
            animation: tl,
            start: 'top 40%',
        })
    }, [])

    useEffect(() => {
        const [ volunteeringElements ] = mobileVolunteeringWrapper.current.children;
        const wrapperElements = mobileVolunteeringTitleWrapper.current.children;

        const background = volunteeringElements.getElementById('Background');
        const firstMan = volunteeringElements.getElementById('firstMan');
        const firstWoman = volunteeringElements.getElementById('firstWoman');
        const secondMan = volunteeringElements.getElementById('secondMan');
        const secondWoman = volunteeringElements.getElementById('secondWoman');
        const dog = volunteeringElements.getElementById('Dog');
        const plant = volunteeringElements.getElementById('Plant');
        const stone = volunteeringElements.getElementById('Stone');

        gsap.set([ background, firstMan, firstWoman, secondMan, secondWoman, dog, plant, stone, ...wrapperElements], { autoAlpha: 0 });

        const tl = gsap.timeline({ default: { ease: 'power3.inOut' } });

        tl.to([background, plant, stone], { duration: 0.5, autoAlpha: 1 })
          .to(dog, { duration: 1, autoAlpha: 1 }, 'dogLabel')
          .to(firstMan, { duration: 1, autoAlpha: 1 }, 'personLabel')
          .to(firstWoman, { duration: 1, autoAlpha: 1 }, 'personLabel+=0.5')
          .to(secondWoman, { duration: 1, autoAlpha: 1 }, 'personLabel+=1')
          .to(secondMan, { duration: 1, autoAlpha: 1 }, 'personLabel+=1.5')
          .fromTo(wrapperElements, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1, stagger: 0.2 }, '-=3')
         

        ScrollTrigger.create({
            trigger: volunteeringElements,
            animation: tl,
            start: 'top 40%',
        })
    }, [])


    return (
        <>
            {/* Mobile */}
            <div className="lg:hidden flex flex-col h-full w-full lg:mb-28">
                <div                     
                    className="flex justify-center"  
                    ref={mobileTaxWrapper}
                >
                    <Tax />
                </div>
                <div ref={mobileTaxTitleWrapper} className="flex flex-col mt-4 mb-16">
                    <h5 className="title font-medium text-2xl">
                        1% Twojego podatku
                    </h5>
                    <p className="font-medium">
                        Dlaczego warto przekazac 1% dla naszego przytuliska?
                    </p>
                    <Link
                        to="/help"
                        className="title text-main-orange font-medium text-base mt-4 hover:text-main-purple transition duration-300 ease-in-out"
                    >
                        Czytaj więcej
                    </Link>
                </div>
                <div 
                    className="flex justify-center" 
                    ref={mobileTransferWrapper}
                >
                    <Transfer />
                </div>
                <div ref={mobileTransferTitleWrapper} className="flex flex-col mt-4 mb-16">
                    <h5 className="title font-medium text-2xl">Przelew</h5>
                    <p className="font-medium">
                        Pomóc zwierzętom możesz także robiąc zwykły przelew.
                    </p>
                    <Link
                        to="/help"
                        className="title text-main-orange font-medium text-base mt-4 hover:text-main-purple transition duration-300 ease-in-out"
                    >
                        Czytaj więcej
                    </Link>
                </div>
                <div 
                    className="flex justify-center" 
                    ref={mobileItemsWrapper}
                >
                    <Items />
                </div>
                <div ref={mobileItemsTitleWrapper} className="flex flex-col mt-4 mb-16">
                    <h5 className="title font-medium text-2xl">
                        Pomoc rzeczowa
                    </h5>
                    <p className="font-medium">
                        Możesz przekazać karmę, zabawki, obroże, środki
                        czystości.
                    </p>
                    <Link
                        to="/help"
                        className="title text-main-orange font-medium text-base mt-4 hover:text-main-purple transition duration-300 ease-in-out"
                    >
                        Czytaj więcej
                    </Link>
                </div>
                <div 
                    className="flex justify-center" 
                    ref={mobileVolunteeringWrapper}
                >
                    <Volunteering />
                </div>
                <div ref={mobileVolunteeringTitleWrapper} className="flex flex-col mt-4 mb-16">
                    <h5 className="title font-medium text-2xl">Wolontariat</h5>
                    <p className="font-medium">
                        Chcesz zostać wolontariuszem? Dowiedz się jak nim
                        zostać.
                    </p>
                    <Link
                        to="/help"
                        className="title text-main-orange font-medium text-base mt-4 hover:text-main-purple transition duration-300 ease-in-out"
                    >
                        Czytaj więcej
                    </Link>
                </div>
            </div>
            {/* Desktop */}
            <div ref={wrapper} className="hidden lg:grid grid-cols-2 h-full w-full lg:mb-28">
                <div className="flex justify-between items-start mr-8 mb-12">
                    <div className="flex flex-col justify-end text-right mr-8">
                        <h5 className="title font-medium text-2xl">
                            1% Twojego podatku
                        </h5>
                        <p className="font-medium mt-4">
                            Dlaczego warto przekazac 1% dla naszego przytuliska?
                        </p>
                        <Link
                            to="/help"
                            className="title text-main-orange font-medium text-base mt-6 hover:text-main-purple transition duration-300 ease-in-out"
                        >
                            Czytaj więcej
                        </Link>
                    </div>
                    <div ref={desktopTaxWrapper}>
                        <Tax />
                    </div>
                </div>
                <div className="flex justify-between items-start ml-8 mb-12">
                    <div ref={desktopTransferWrapper}>
                        <Transfer />
                    </div>
                    <div className="flex flex-col justify-start text-left ml-8">
                        <h5 className="title font-medium text-2xl">Przelew</h5>
                        <p className="font-medium mt-4">
                            Pomóc zwierzętom możesz także robiąc zwykły przelew.
                        </p>
                        <Link
                            to="/help"
                            className="title text-main-orange font-medium text-base mt-6 hover:text-main-purple transition duration-300 ease-in-out"
                        >
                            Czytaj więcej
                        </Link>
                    </div>
                </div>
                <div className="flex justify-between items-start mr-8">
                    <div className="flex flex-col justify-end text-right mr-8">
                        <h5 className="title font-medium text-2xl">
                            Pomoc rzeczowa
                        </h5>
                        <p className="font-medium mt-4">
                            Możesz przekazać karmę, zabawki, obroże, środki
                            czystości.
                        </p>
                        <Link
                            to="/help"
                            className="title text-main-orange font-medium text-base mt-6 hover:text-main-purple transition duration-300 ease-in-out"
                        >
                            Czytaj więcej
                        </Link>
                    </div>
                    <div ref={desktopItemsWrapper}>
                        <Items />
                    </div>
                </div>
                <div className="flex justify-between items-start ml-8">
                    <div ref={desktopVolunteeringWrapper}>
                        <Volunteering />
                    </div>
                    <div className="flex flex-col justify-start text-left ml-8">
                        <h5 className="title font-medium text-2xl">
                            Wolontariat
                        </h5>
                        <p className="font-medium mt-4">
                            Chcesz zostać wolontariuszem? Dowiedz się jak nim
                            zostać.
                        </p>
                        <Link
                            to="/help"
                            className="title text-main-orange font-medium text-base mt-6 hover:text-main-purple transition duration-300 ease-in-out"
                        >
                            Czytaj więcej
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HelpItems;
