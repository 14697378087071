import React, { useRef, useEffect } from 'react';

import PetItem from './PetItem';

const PetsList = props => {
    const wrapper = useRef(null);
    const { pets, petsWrapperHandler } = props;

    useEffect(() => {
        const wrapperElements = wrapper.current;
        petsWrapperHandler(wrapperElements)
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        pets && (
            <div ref={wrapper} className="w-full h-5/6 flex flex-col lg:flex-row lg:mb-28">
                {pets.map(pet => (
                    <PetItem
                        key={pet.type}
                        type={pet.type}
                        counter={pet.counter}
                        image={pet.image}
                        title={pet.title}
                        className={pet.className}
                    />
                ))}
            </div>
        )
    );
};

export default PetsList;
