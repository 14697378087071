import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form, useField } from 'formik';
import * as yup from 'yup';
import {
    IconBrandFacebook,
    IconBrandInstagram,
    IconBrandMessenger,
    IconMail,
    IconMapPin,
    IconUser,
} from '@tabler/icons';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import * as actions from '../../store/actions/index'
import { ReactComponent as ContactTR } from '../../assets/images/Contact_tr.svg'; // Top Right
import { ReactComponent as ContactTL } from '../../assets/images/Contact_tl.svg'; //Top Left
import { ReactComponent as ContactBL } from '../../assets/images/Contact_bl.svg'; // Bottom Left
import { ReactComponent as ContactBR } from '../../assets/images/Contact_br.svg'; //Bottom Right
import Input from '../../shared/components/form/Input';
import Button from '../../shared/components/ui/Button';
import SuccessModal from '../../shared/components/ui/SuccessModal';

const validationSchema = yup.object().shape({
    name: yup.string().required('Pole Imię jest wymagane.'),
    email: yup
        .string()
        .email('Podany adres email jest nieprawidłowy.')
        .required('Pole Email jest wymagane.'),
    message: yup
        .string()
        .min(50, 'Wiadomość musi zawierać minimum 50 znaków.')
        .required('Pole Wiadomość jest wymagane.'),
});

gsap.registerPlugin(ScrollTrigger);

const CustomField = props => {
    const [field, meta] = useField(props);
    const errorMessage = meta.error && meta.touched ? meta.error : '';
    return (
        <Input
            {...field}
            {...props}
            errorMessage={errorMessage}
            isError={!!errorMessage}
        />
    );
};

const Contact = props => {
    const { sendMail, message } = props;

    const [openModal, isOpenModal] = useState(false);
    
    const contactWrapper = useRef(null);
    const form = useRef(null);

    useEffect(() => {
        const contactElements = contactWrapper.current.children;
        const formElement = form.current;

        gsap.set([...contactElements, formElement], { autoAlpha: 0 });

        const tl = gsap.timeline({ default: { ease: 'Power3.inOut' } });

        tl.fromTo(
            contactElements,
            { y: '-=50' },
            { duration: 1.5, y: '+=50', autoAlpha: 1, stagger: 0.2 },
            'contactLabel'
        ).fromTo(
            formElement,
            { y: '-=50' },
            { duration: 1.5, y: '+=50', autoAlpha: 1 },
            'contactLabel'
        );

        ScrollTrigger.create({
            trigger: contactElements,
            animation: tl,
            start: 'top 50%',
        });
    }, []);

    useEffect(() => {
        if (message !== null) {
            isOpenModal(true);
        }
    }, [message])

    const onOpenModal = isOpen => {
        isOpenModal(isOpen);
    };

    return (
    <>
        {openModal && <SuccessModal isOpen={openModal} onOpenModal={onOpenModal} message={message}/>}
        <section
            id="Contact"
            className="flex w-full justify-start lg:justify-center bg-white relative"
        >
            <ContactTL className="hidden lg:flex absolute top-12 left-0" />
            <ContactTR className="hidden lg:flex absolute top-0 right-0 max-w-sm xl:max-w-max" />
            <ContactBL className="hidden lg:flex absolute bottom-0 left-0 " />
            <ContactBR className="hidden lg:flex absolute bottom-32 right-0" />
            <div className="grid grid-cols-1 lg:grid-cols-2 p-8 items-start lg:justify-between w-full max-w-screen-content z-10 overflow-hidden mt-8 lg:my-36">
                <div ref={contactWrapper} className="flex flex-col h-full">
                    <h1 className="title text-2xl font-medium text-main-orange uppercase pb-4">
                        Chcesz dowiedzieć się czegoś więcej?
                    </h1>
                    <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl pb-6">
                        Skontaktuj się z nami
                    </h1>
                    <p className="text-base leading-7 lg:leading-8 w-full lg:w-5/6 pb-12">
                        Potrzebujesz informacji w sprawie adopcji, a może chcesz
                        nasz wspomóc. Skontaktuj się z nami.
                    </p>
                    <div className="flex items-center ml-4 mb-8">
                        <IconMapPin
                            size={24}
                            stroke={1.5}
                            className="text-main-purple mr-6"
                        />
                        <p className="title font-light text-2xl">
                            Słowackiego 32, 59-400 Jawor
                        </p>
                    </div>
                    <div className="flex items-center ml-4 mb-8">
                        <IconMail
                            size={24}
                            stroke={1.5}
                            className="text-main-purple mr-6"
                        />
                        <p className="title font-light text-2xl">
                            jawor@toz.pl
                        </p>
                    </div>
                    <div className="flex items-center ml-4 mb-8 lg:mb-0">
                        <IconBrandMessenger
                            size={24}
                            stroke={1.5}
                            className="text-main-purple mr-6"
                        />
                        <p className="title font-light text-2xl">
                            facebook.com/tozjawor
                        </p>
                    </div>
                    <div className="flex items-end h-full mb-8 lg:mb-0">
                        <Link
                            to="\\facebook.com/tozjawor"
                            target="_blank"
                            className="p-4 mr-2 cursor-pointer bg-main-orange text-white hover:bg-gray-700 rounded-full transition duration-200 ease-in-out"
                        >
                            <IconBrandFacebook size={24} stroke={1.5} />
                        </Link>
                        <Link
                            to="\\instagram.com/tozjawor/?hl=pl"
                            target="_blank"
                            className="p-4 cursor-pointer bg-main-purple text-white hover:bg-gray-700 rounded-full transition duration-200 ease-in-out"
                        >
                            <IconBrandInstagram size={24} stroke={1.5} />
                        </Link>
                    </div>
                </div>
                <div
                    ref={form}
                    className="bg-main-blue rounded-lg w-full h-full"
                >
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            message: '',
                        }}
                        onSubmit={(data, { setSubmitting, resetForm, values }) => {
                            setSubmitting(true);
                            let formData = {
                                name: data.name,
                                email: data.email,
                                message: data.message,
                            };
                            sendMail(formData);
                                resetForm({
                                    values: {
                                        name: '',
                                        email: '',
                                        message: ''
                                    }
                                });
                            setSubmitting(false);
                        }}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <Form className="flex flex-col justify-around w-full p-4 lg:p-12">
                                <CustomField
                                    element="input-lp"
                                    type="text"
                                    name="name"
                                    label="Twoje imię"
                                    labelClasses="title font-light text-base lg:text-xl"
                                >
                                    <IconUser
                                        size={24}
                                        stroke={1.5}
                                        className="absolute text-main-purple left-4 top-1/4"
                                    />
                                </CustomField>
                                <CustomField
                                    element="input-lp"
                                    type="email"
                                    name="email"
                                    label="Twój adres email"
                                    labelClasses="title font-light text-base lg:text-xl"
                                >
                                    <IconMail
                                        size={24}
                                        stroke={1.5}
                                        className="absolute text-main-purple left-4 top-1/4"
                                    />
                                </CustomField>
                                <CustomField
                                    element="textarea-lp"
                                    name="message"
                                    label="Twoja wiadomość"
                                    placeholder="Twoja wiadomość"
                                    rows={8}
                                    labelClasses="title font-light text-base lg:text-xl"
                                />
                                <div className="flex items-center justify-center lg:justify-end mt-8">
                                    <Button
                                        isLoading={isSubmitting}
                                        className="relative p-4"
                                        element="lp-filled-orange-submit"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Wyślij wiadomość
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </section>
    </>
    );
};

const mapStateToProps = state => {
    return {
        message: state.mail.message,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendMail: (formData) => dispatch(actions.sendMailAsync(formData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
