import React from 'react';
import { NavLink } from 'react-router-dom';

const NavbarItems = () => {
    return (
        <ul className="flex flex-col lg:flex-row xl:space-x-10 text-base text-white mt-8 lg:mt-0">
            <li className="wrapper py-3 pr-3 flex items-center">
                <NavLink to="/pets">Nasze Zwierzęta</NavLink>
            </li>
            <li className="wrapper py-3 pr-3 flex items-center">
                <NavLink to="/articles">Aktualności</NavLink>
            </li>
            <li className="wrapper py-3 pr-3 flex items-center">
                <NavLink to="/help">Pomoc</NavLink>
            </li>
            <li className="wrapper py-3 pr-3 flex items-center">
                <NavLink to="/adoptions">Zaadoptowane</NavLink>
            </li>
            <li className="wrapper py-3 pr-3 flex items-center">
                <NavLink to="/faq">Faq</NavLink>
            </li>
        </ul>
    );
};

export default NavbarItems;
