import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    errors: [],
    loading: false,
    message: null,
    articles: [],
    article: null,
    content: null,
};

const startArticle = (state, action) => {
    return updateObject(state, { errors: null, loading: true, message: null });
};

const failArticle = (state, action) => {
    return updateObject(state, { errors: action.errors, loading: false });
};

const successArticle = (state, action) => {
    return updateObject(state, {
        message: action.message,
        errors: null,
        loading: false,
    });
};

const clearArticle = (state, action) => {
    return updateObject(state, {
        article: null,
    });
};

const indexArticle = (state, action) => {
    return updateObject(state, {
        articles: action.articles,
        errors: null,
        loading: false,
    });
};

const showArticle = (state, action) => {
    return updateObject(state, {
        article: action.article,
        errors: null,
        loading: false,
    });
};

const destroyArticle = (state, action) => {
    return updateObject(state, {
        articles: {
            ...state.articles,
            articles: state.articles.articles.filter(
                article => article._id !== action.articleId
            ),
        },
    });
};

const articleContent = (state, action) => {
    return updateObject(state, {
        content: action.content,
    });
};

const clearMessageErrorsArticle = (state, action) => {
    return updateObject(state, {
        errors: null,
        message: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.START_ARTICLE:
            return startArticle(state, action);
        case actionsTypes.FAIL_ARTICLE:
            return failArticle(state, action);
        case actionsTypes.SUCCESS_ARTICLE:
            return successArticle(state, action);
        case actionsTypes.CLEAR_ARTICLE:
            return clearArticle(state, action);
        case actionsTypes.INDEX_ARTICLE:
            return indexArticle(state, action);
        case actionsTypes.SHOW_ARTICLE:
            return showArticle(state, action);
        case actionsTypes.DESTROY_ARTICLE:
            return destroyArticle(state, action);
        case actionsTypes.CONTENT_ARTICLE:
            return articleContent(state, action);
        case actionsTypes.CLEAR_MESSAGE_ERRORS_ARTICLE:
            return clearMessageErrorsArticle(state, action);
        default:
            return state;
    }
};

export default reducer;
