import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { IconCalendar } from '@tabler/icons';

import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg';
import * as actions from '../../store/actions/index';
import { dateFormat } from '../../shared/utils/dateFormat';

const PetShow = props => {
    const petId = useParams().petId;
    const { cat, dog, showDog, showCat, clearDog, clearCat, isDogs } = props;

    const dateHandler = () => {
        if (isDogs) {
            const setDate = new Date(dog.createdAt);
            const formattedDate = dateFormat(setDate);
            return formattedDate;
        }
        const setDate = new Date(cat.createdAt);
        const formattedDate = dateFormat(setDate);
        return formattedDate;
    };

    useEffect(() => {
        if (isDogs) {
            showDog(petId);
        } else {
            showCat(petId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDogs]);

    useEffect(
        () => () => {
            if (isDogs) {
                clearDog();
            } else {
                clearCat();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <section className="p-8 flex w-full h-full lg:min-h-screen justify-center relative lg:p-0">
            <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto" />
            {dog && (
                <div className="h-full flex flex-col w-full max-w-screen-md z-10 mt-32 space-y-4 mb-8 lg:mb-28">
                    <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl">
                        {dog.name}
                    </h1>
                    <span className="title text-2xl font-medium text-gray-800">
                        {dog.sex} / {dog.age}
                    </span>
                    <span className="flex items-center font-semibold text-lg title-font text-gray-500 mb-6">
                        <IconCalendar
                            size={24}
                            stroke={1.5}
                            className="text-gray-500 mr-2"
                        />
                        {dateHandler()}
                    </span>
                    <div className="h-4/6 rounded-lg mb-6">
                        <img
                            className="w-full h-4/6 rounded-lg object-cover"
                            src={process.env.REACT_APP_API_URL + dog.image}
                            alt={`Zdjęcie ${dog.name}`}
                        />
                    </div>
                    <div className="mb-12 text-justify">
                        {ReactHtmlParser(dog.description)}
                    </div>
                </div>
            )}
            {cat && (
                <div className="h-full flex flex-col w-full max-w-screen-md z-10 mt-32 space-y-4 mb-8 lg:mb-28">
                    <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl">
                        {cat.name}
                    </h1>
                    <span className="title text-2xl font-medium text-gray-800">
                        {cat.sex} / {cat.age}
                    </span>
                    <span className="flex items-center font-semibold text-lg title-font text-gray-500 mb-6">
                        <IconCalendar
                            size={24}
                            stroke={1.5}
                            className="text-gray-500 mr-2"
                        />
                        {dateHandler()}
                    </span>
                    <div className="h-4/6 rounded-lg mb-6">
                        <img
                            className="w-full h-4/6 rounded-lg object-cover"
                            src={process.env.REACT_APP_API_URL + cat.image}
                            alt={`Zdjęcie ${cat.name}`}
                        />
                    </div>
                    <div className="mb-12 text-justify">
                        {ReactHtmlParser(cat.description)}
                    </div>
                </div>
            )}
        </section>
    );
};

const mapStateToProps = state => {
    return {
        dog: state.dogs.dog,
        cat: state.cats.cat,
        isDogs: state.others.isDogs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showDog: petId => dispatch(actions.showDogAsync(petId)),
        showCat: petId => dispatch(actions.showCatAsync(petId)),
        clearDog: () => dispatch(actions.clearDog()),
        clearCat: () => dispatch(actions.clearCat()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PetShow);
