import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { ReactComponent as AdoptionsTR } from '../../assets/images/Adoptions_tr.svg'; // Top Right
import { ReactComponent as AdoptionsTL } from '../../assets/images/Adoptions_tl.svg'; //Top Left
import { ReactComponent as AdoptionsBL } from '../../assets/images/Adoptions_bl.svg'; // Bottom Left
import { ReactComponent as AdoptionsBR } from '../../assets/images/Adoptions_br.svg'; //Bottom Right
import AdoptionsList from '../components/Adoptions/AdoptionsList';
import * as actions from '../../store/actions/index';

gsap.registerPlugin(ScrollTrigger);

const Adoptions = props => {
    const [ adoptionsListRefData, setAdoptionsListRefData ] = useState(null)
    const wrapper = useRef(null);
    const { indexAdoption, adoptions } = props;

    useEffect(() => {
        indexAdoption(1, 3);
    }, [indexAdoption]);

    useEffect(() => {
        if (adoptionsListRefData) {
            const wrapperElements = wrapper.current.children;  
                 
            const [firstTitle, secondTitle, paragraph] = wrapperElements;

            gsap.set([firstTitle, secondTitle, paragraph, adoptionsListRefData.desktop, adoptionsListRefData.mobile], { autoAlpha: 0 });
            
            const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

            tl.fromTo(firstTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel')
            .fromTo(secondTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.2')
            .fromTo(paragraph, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.4')
            .fromTo(adoptionsListRefData.desktop, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.6')
            .fromTo(adoptionsListRefData.mobile, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.6')

            ScrollTrigger.create({
                trigger: wrapperElements,
                animation: tl,
                start: 'top 50%',
            })
        }

    }, [adoptionsListRefData])

    const adoptionsWrapperHandler = refData => {
        setAdoptionsListRefData(refData);
    }

    return (
        <section className="flex w-full min-h-screen justify-start lg:justify-center bg-white relative">
            <AdoptionsTL className="hidden lg:flex absolute top-12 left-0" />
            <AdoptionsTR className="hidden lg:flex absolute top-0 right-0 max-w-sm xl:max-w-max" />
            <AdoptionsBL className="hidden lg:flex absolute bottom-0 left-0 " />
            <AdoptionsBR className="hidden lg:flex absolute bottom-32 right-0" />
            <div ref={wrapper} className="flex flex-col min-h-screen p-8 items-start lg:items-center lg:justify-between w-full max-w-screen-content z-10 overflow-hidden">
                <h1 className="title text-2xl font-medium text-main-orange uppercase mt-8 lg:mt-36 pb-4">
                    Zwierzaki w nowym domu
                </h1>
                <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl pb-6">
                    Zaadoptowane zwierzęta
                </h1>
                <p className="text-base lg:text-center leading-7 lg:leading-8 lg:w-2/5 pb-12">
                    Z dumą chcielibyśmy Państwu przedstawić naszych
                    podopiecznych, którzy odnaleźli nowych przyjaciół i
                    otrzymali kochający dom
                </p>
                {adoptions.adoptions && (
                    <AdoptionsList 
                        adoptions={adoptions.adoptions} 
                        adoptionsWrapperHandler={adoptionsWrapperHandler} 
                    />
                )}
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        adoptions: state.adoptions.adoptions,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        indexAdoption: (page, limit) =>
            dispatch(actions.paginatedIndexAdoptionAsync(page, limit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Adoptions);
