import React from 'react';
import { Field } from 'formik';
import { RiArrowDropDownLine } from 'react-icons/ri';

import './Input.css';

const Input = props => {
    let inputElement = null;

    switch (props.element) {
        case 'input':
            inputElement = (
                <Field
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    className={`text-gray-700 dark:text-white focus:outline-none 
                    focus:border-indigo-300 border border-gray-200  dark:border-gray-900 rounded py-2 
                    px-4 mt-2 block w-full appearance-none dark:bg-gray-700  ${
                        props.isError &&
                        'bg-red-100 dark:bg-red-100 border-red-500 focus:border-red-500 dark:text-gray-700'
                    }`}
                />
            );
            break;
        case 'textarea':
            inputElement = (
                <Field
                    name={props.name}
                    rows={props.rows}
                    cols={props.cols}
                    placeholder={props.placeholder}
                    as={props.element}
                    className={`bg-white text-gray-700 dark:text-white focus:outline-none 
                    focus:border-indigo-300 border border-gray-200 dark:border-gray-900 rounded py-2 
                    px-4 mt-2 block w-full appearance-none dark:bg-gray-700 ${
                        props.isError &&
                        'bg-red-100 border-red-500 dark:bg-red-100 dark:text-gray-700'
                    }`}
                >
                    {props.children}
                </Field>
            );
            break;
        case 'select':
            inputElement = (
                <div className="relative">
                    <Field
                        as="select"
                        name={props.name}
                        className={`text-gray-700 dark:text-white focus:outline-none 
                    focus:border-indigo-300 border border-gray-200 dark:border-gray-900 rounded py-2 
                    px-4 mt-2 block w-full appearance-none dark:bg-gray-700 ${
                        props.isError &&
                        'bg-red-100 border-red-500 focus:border-red-500'
                    } ${props.className}`}
                    >
                        {props.children}
                    </Field>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-white">
                        <RiArrowDropDownLine size={16} />
                    </div>
                </div>
            );
            break;
        case 'radio':
            inputElement = (
                <label className="flex items-center">
                    <Field
                        type="radio"
                        name={props.name}
                        value={props.value}
                        className="mr-1 form-radio w-5 h-5 border border-gray-300 rounded-full outline-none cursor-pointer checked:bg-blue-400"
                    />
                    <span className="dark:text-white">{props.label}</span>
                </label>
            );
            break;
        default:
            inputElement = (
                <Field
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 mt-2 block w-full appearance-none"
                />
            );
            break;
        case 'input-lp':
            inputElement = (
                <div className="relative">
                    <Field
                        name={props.name}
                        type={props.type}
                        placeholder={props.placeholder}
                        className={`bg-second-blue text-gray-700 focus:outline-none 
                    focus:border-indigo-300 border border-second-blue rounded py-3 
                    pl-12 pr-6 mt-2 block w-full appearance-none   ${
                        props.isError &&
                        'bg-red-100 dark:bg-red-100 border-red-500 focus:border-red-500 dark:text-gray-700'
                    }`}
                    />
                    {props.children}
                </div>
            );
            break;
        case 'textarea-lp':
            inputElement = (
                <Field
                    name={props.name}
                    rows={props.rows}
                    cols={props.cols}
                    placeholder={props.placeholder}
                    as="textarea"
                    className={`placeholder-text text-lg bg-second-blue text-gray-700 focus:outline-none 
                    focus:border-indigo-300 border border-second-blue rounded py-3
                    px-4 mt-2 block w-full appearance-none  ${
                        props.isError &&
                        'bg-red-100 border-red-500 dark:bg-red-100 dark:text-gray-700'
                    }`}
                />
            );
            break;
    }

    return (
        <div className="mt-4">
            {props.element !== 'radio' && (
                <div className="flex justify-between">
                    <label
                        htmlFor={props.name}
                        className={`flex flex-col text-gray-700 dark:text-white ${props.labelClasses}`}
                    >
                        {props.label}
                    </label>
                    <p className="text-xs text-red-600 font-semibold">
                        {props.errorMessage}
                    </p>
                </div>
            )}
            {inputElement}
        </div>
    );
};

export default Input;
