import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    isDogs: true,
}

const isDogs = (state, action) => {
    return updateObject(state, {
        isDogs: action.isDogs
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.IS_DOGS:
            return isDogs(state, action);
        default:
            return state;    
    }
}

export default reducer;