import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AdoptionItem from './AdoptionItem';
import SpinnerPaw from '../../../shared/components/ui/SpinnerPaw';

const AdoptionsList = props => {
    const { adoptions, loading } = props;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isLoading !== loading) {
                setIsLoading(loading);
            }
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [isLoading, loading]);

    return (
        <>
            {isLoading && <SpinnerPaw className="absolute" loading={isLoading} />}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 h-full lg:mb-28">
                {adoptions.map(adoption => {
                    const setDate = new Date(adoption.createdAt);
                    const date = setDate.toLocaleDateString();
                    return (
                        <AdoptionItem
                            key={adoption._id}
                            id={adoption._id}
                            image={adoption.image}
                            name={adoption.name}
                            sex={adoption.sex}
                            age={adoption.age}
                            owner={adoption.owner}
                            date={date}
                        />
                    );
                })}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.adoptions.loading,
    };
};

export default connect(mapStateToProps)(AdoptionsList);
