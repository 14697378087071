import * as actionTypes from './action_types';
import axios from 'axios';

export const clearMessageErrorsAdoption = () => {
    return {
        type: actionTypes.CLEAR_MESSAGE_ERRORS_ADOPTION,
    };
};

export const startAdoption = () => {
    return {
        type: actionTypes.START_ADOPTION,
    };
};

export const failAdoption = errors => {
    return {
        type: actionTypes.FAIL_ADOPTION,
        errors,
    };
};

export const successAdoption = message => {
    return {
        type: actionTypes.SUCCESS_ADOPTION,
        message,
    };
};

export const clearAdoption = () => {
    return {
        type: actionTypes.CLEAR_ADOPTION,
    };
};

export const indexAdoption = adoptions => {
    return {
        type: actionTypes.INDEX_ADOPTION,
        adoptions,
    };
};

export const showAdoption = adoption => {
    return {
        type: actionTypes.SHOW_ADOPTION,
        adoption,
    };
};

export const destroyAdoption = adoptionId => {
    return {
        type: actionTypes.DESTROY_ADOPTION,
        adoptionId,
    };
};

export const indexAdoptionAsync = () => {
    return async dispatch => {
        try {
            dispatch(startAdoption());
            const response = await axios.get('/api/adoption');
            dispatch(indexAdoption(response.data.adoptions));
        } catch (error) {
            dispatch(failAdoption(error.response.data.errors));
        }
    };
};

export const paginatedIndexAdoptionAsync = (page, limit) => {
    return async dispatch => {
        try {
            dispatch(startAdoption());
            const response = await axios.get(
                `/api/adoption/paginated?page=${page}&limit=${limit}`
            );
            dispatch(indexAdoption(response.data.adoptions));
        } catch (error) {
            dispatch(failAdoption(error.response.data.errors));
        }
    };
};

export const searchAdoptionAsync = nameInclude => {
    return async dispatch => {
        try {
            dispatch(startAdoption());
            const response = await axios.get(
                `/api/adoption/search?nameInclude=${nameInclude}`
            );
            dispatch(indexAdoption(response.data.adoptions));
        } catch (error) {
            dispatch(failAdoption(error.response.data.errors));
        }
    };
};

export const storeAdoptionAsync = (formData, token) => {
    return async dispatch => {
        try {
            dispatch(startAdoption());
            const response = await axios.post('/api/adoption', formData, {
                headers: {
                    Accept: 'appliAdoptionion/json',
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
            });
            dispatch(successAdoption(response.data.message));
        } catch (error) {
            dispatch(failAdoption(error.response.data.errors));
        }
    };
};

export const showAdoptionAsync = adoptionId => {
    return async dispatch => {
        try {
            // dispatch(clearAdoption());
            dispatch(startAdoption());
            const response = await axios.get(`/api/adoption/${adoptionId}`);
            dispatch(showAdoption(response.data.adoption));
        } catch (error) {
            dispatch(failAdoption(error.response.data.errors));
        }
    };
};

export const updateAdoptionAsync = (formData, token, adoptionId) => {
    return async dispatch => {
        try {
            dispatch(startAdoption());
            const response = await axios.patch(
                `/api/adoption/${adoptionId}`,
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(successAdoption(response.data.message));
        } catch (error) {
            dispatch(failAdoption(error.response.data.errors));
        }
    };
};

export const destroyAdoptionAsync = (adoptionId, token) => {
    return async dispatch => {
        try {
            dispatch(startAdoption());
            const response = await axios.delete(`/api/adoption/${adoptionId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(destroyAdoption(adoptionId));
            dispatch(successAdoption(response.data.message));
        } catch (error) {
            console.log(error);
            dispatch(failAdoption(error));
        }
    };
};
