import React from 'react';
import { IconHeart, IconEdit, IconTrash, IconArrowNarrowRight } from '@tabler/icons'
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

import './Button.css';

const Button = props => {
    let btn = null;

    // console.log('[Button.js]');

    switch (props.element) {
        case 'lp-border-purple':
            btn = (
                <button
                    to={props.to}
                    target={props.target}
                    onClick={props.onClick}
                    className={`flex items-center filled-purple text-white font-medium bg-gradient-to-r from-second-purple to-main-purple py-3 px-4 rounded-lg outline-none focus:outline-none transition duration-200 ease-in-out ${props.className}`}
                >
                    <IconHeart size={24} stroke={1.5} className="mr-1 -ml-1" />
                    {props.children}
                </button>
            );
            break;
        case 'lp-filled-purple':
            btn = (
                <button
                    onClick={props.onClick}
                    className={`filled-purple text-white bg-gradient-to-r from-second-purple to-main-purple rounded-lg outline-none focus:outline-none ${props.className}`}
                >
                    {props.children}
                </button>
            );
            break;
        case 'lp-filled-orange':
            btn = (
                <button
                    onClick={props.onClick}
                    className={`filled-orange text-white bg-gradient-to-r from-second-orange to-main-orange rounded-lg outline-none focus:outline-none ${props.className}`}
                >
                    {props.children}
                </button>
            );
            break;
        case 'switch-pet':
            btn = (
                <button
                    onClick={props.onClick}
                    className={`flex items-center font-medium text-lg text-gray-800 hover:text-main-orange outline-none focus:outline-none transition duration-200 ease-in-out ${props.className}`}
                >
                    {props.children}
                    <IconArrowNarrowRight size={24} stroke={1.5} />
                </button>
            );
            break;
        case 'submit':
            btn = (
                <button
                    type={props.type}
                    disabled={props.disabled}
                    className={`${props.className} flex items-center text-white font-semibold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed outline-none focus:outline-none transition duration-200 ease-in-out`}
                >
                    {props.children}
                    {props.loading && <Spinner />}
                </button>
            );
            break;
        case 'lp-filled-orange-submit':
            btn = (
                <button
                    type={props.type}
                    disabled={props.disabled}
                    className={`filled-orange text-white bg-gradient-to-r from-second-orange to-main-orange rounded-lg outline-none focus:outline-none ${props.className}`}
                >
                    {props.children}
                    {props.loading && <Spinner />}
                </button>
            );
            break;
        case 'basic-blue':
            btn = (
                <button
                    type={props.type}
                    disabled={props.disabled}
                    className={`flex items-center bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded outline-none focus:outline-none  ${props.className}`}
                >
                    {props.children}
                </button>
            );
            break;
        case 'edit':
            btn = (
                <Link
                    to={props.to}
                    exact={props.exact}
                    className={`flex items-center justify-center p-2 mt-4 rounded-lg text-cyan-800 dark:text-white bg-cyan-100 dark:bg-cyan-800 hover:bg-white dark:hover:bg-gray-900 border-2 border-cyan-100 dark:border-cyan-800 outline-none focus:outline-none transition duration-200 ease-in-out ${props.customClasses}`}
                >
                    <IconEdit size={props.size} stroke={1.5} />
                    {/* <span className="font-semibold">Edytuj</span> */}
                </Link>
            );
            break;
        case 'delete':
            btn = (
                <button
                    onClick={props.openDeleteModal}
                    className={`flex items-center justify-center p-2 mt-4 ml-1 rounded-lg text-red-800 dark:text-white bg-red-100 dark:bg-red-800 hover:bg-white dark:hover:bg-gray-900 border-2 border-red-100 dark:border-red-800 outline-none focus:outline-none transition duration-200 ease-in-out ${props.customClasses}`}
                >
                    <IconTrash size={props.size} stroke={1.5} />
                    {/* <span className="font-semibold">Usuń</span> */}
                </button>
            );
            break;
        case 'cancel':
            btn = (
                <button
                    type="button"
                    class="p-4 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 outline-none focus:outline-none transition duration-200 ease-in-out"
                >
                    {props.text}
                </button>
            );
            break;
        case 'link-back':
            btn = (
                <Link
                    to={props.to}
                    exact={props.exact}
                    className={`${props.className} transition duration-200 ease-in-out text-cyan-700 dark:text-white dark:hover:text-cyan-700 bg-white hover:bg-cyan-50 dark:bg-gray-800 dark:hover:bg-white border-cyan-50 dark:border-gray-400 dark:hover:border-white font-semibold py-2 px-4 rounded outline-none focus:outline-none`}
                >
                    {props.children}
                </Link>
            );
            break;
        default:
            break;
    }

    return <>{btn}</>;
};

export default Button;
