import * as actionTypes from './action_types';
import axios from 'axios';

export const clearMessageErrorsDog = () => {
    return {
        type: actionTypes.CLEAR_MESSAGE_ERRORS_DOG,
    };
};

export const startDog = () => {
    return {
        type: actionTypes.START_DOG,
    };
};

export const failDog = errors => {
    return {
        type: actionTypes.FAIL_DOG,
        errors,
    };
};

export const successDog = message => {
    return {
        type: actionTypes.SUCCESS_DOG,
        message,
    };
};

export const clearDog = () => {
    return {
        type: actionTypes.CLEAR_DOG,
    };
};

export const indexDog = dogs => {
    return {
        type: actionTypes.INDEX_DOG,
        dogs,
    };
};

export const showDog = dog => {
    return {
        type: actionTypes.SHOW_DOG,
        dog,
    };
};

export const destroyDog = dogId => {
    return {
        type: actionTypes.DESTROY_DOG,
        dogId,
    };
};

export const counterDog = amount => {
    return {
        type: actionTypes.COUNTER_DOG,
        amount,
    };
};

export const indexDogAsync = () => {
    return async dispatch => {
        try {
            dispatch(startDog());
            const response = await axios.get('/api/dog');
            dispatch(indexDog(response.data.dogs));
        } catch (error) {
            dispatch(failDog(error.response.data.errors));
        }
    };
};

export const storeDogAsync = (formData, token) => {
    return async dispatch => {
        try {
            dispatch(startDog());
            const response = await axios.post('/api/dog', formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
            });
            dispatch(successDog(response.data.message));
        } catch (error) {
            dispatch(failDog(error.response.data.errors));
        }
    };
};

export const showDogAsync = dogId => {
    return async dispatch => {
        try {
            dispatch(startDog());
            const response = await axios.get(`/api/dog/${dogId}`);
            dispatch(showDog(response.data.dog));
        } catch (error) {
            dispatch(failDog(error.response.data.errors));
        }
    };
};

export const updateDogAsync = (formData, token, dogId) => {
    return async dispatch => {
        try {
            dispatch(startDog());
            const response = await axios.patch(`/api/dog/${dogId}`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(successDog(response.data.message));
        } catch (error) {
            dispatch(failDog(error.response.data.errors));
        }
    };
};

export const destroyDogAsync = (dogId, token) => {
    return async dispatch => {
        try {
            dispatch(startDog());
            const response = await axios.delete(`/api/dog/${dogId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(destroyDog(dogId));
            dispatch(successDog(response.data.message));
        } catch (error) {
            dispatch(failDog(error.response.data.errors));
        }
    };
};

export const counterDogAsync = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`/api/dog/counter`);
            dispatch(counterDog(response.data.dogsCounter));
        } catch (error) {
            dispatch(failDog(error.response.data.errors));
        }
    };
};
