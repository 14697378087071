import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    message: null,
    errors: [],
    loading: false,
};

const startMail = (state, action) => {
    return updateObject(state, {
        errors: null,
        message: null,
        loading: true,
    });
};

const successMail = (state, action) => {
    return updateObject(state, {
        message: action.message,
        errors: null,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.SUCCESS_MAIL:
            return successMail(state, action);
        case actionsTypes.START_MAIL:
            return startMail(state, action);
        default:
            return state;
    }
};

export default reducer;
