import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    errors: [],
    loading: false,
    message: null,
    cats: [],
    cat: null,
    counter: null,
};

const clearMessageErrorsCat = (state, action) => {
    return updateObject(state, {
        errors: null,
        message: null,
    });
};

const startCat = (state, action) => {
    return updateObject(state, { errors: null, loading: true, message: null });
};

const failCat = (state, action) => {
    return updateObject(state, { errors: action.errors, loading: false });
};

const successCat = (state, action) => {
    return updateObject(state, {
        message: action.message,
        errors: null,
        loading: false,
    });
};

const clearCat = (state, action) => {
    return updateObject(state, {
        cat: null,
    });
};

const indexCat = (state, action) => {
    return updateObject(state, {
        cats: action.cats,
        errors: null,
        loading: false,
    });
};

const showCat = (state, action) => {
    return updateObject(state, {
        cat: action.cat,
        errors: null,
        loading: false,
    });
};

const destroyCat = (state, action) => {
    return updateObject(state, {
        cats: state.cats.filter(cat => cat._id !== action.catId),
    });
};

const counterCat = (state, action) => {
    return updateObject(state, {
        counter: action.amount,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.CLEAR_MESSAGE_ERRORS_CAT:
            return clearMessageErrorsCat(state, action);
        case actionsTypes.START_CAT:
            return startCat(state, action);
        case actionsTypes.FAIL_CAT:
            return failCat(state, action);
        case actionsTypes.SUCCESS_CAT:
            return successCat(state, action);
        case actionsTypes.CLEAR_CAT:
            return clearCat(state, action);
        case actionsTypes.INDEX_CAT:
            return indexCat(state, action);
        case actionsTypes.SHOW_CAT:
            return showCat(state, action);
        case actionsTypes.COUNTER_CAT:
            return counterCat(state, action);
        case actionsTypes.DESTROY_CAT:
            return destroyCat(state, action);
        default:
            return state;
    }
};

export default reducer;
