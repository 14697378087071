import React, { useState } from 'react';

import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg';
import '../../shared/css/UnderlineTitle.css';

const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="border border-gray-50 rounded shadow-lg min-w-0 min-h-0 overflow-hidden">
            <button
                type="button"
                aria-label="Open item"
                title="Open item"
                className="flex items-center justify-between w-full p-4 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                <p className="text-lg font-medium">{title}</p>
                <div
                    className={`flex items-center justify-center w-8 h-8 border rounded-lg ${
                        isOpen ? 'border-main-orange' : ''
                    }`}
                >
                    <svg
                        viewBox="0 0 24 24"
                        className={`w-3 text-gray-600 transition-transform duration-200 ${
                            isOpen
                                ? 'transform rotate-180 text-main-orange'
                                : ''
                        }`}
                    >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="2,7 12,17 22,7"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </button>
            {isOpen && (
                <div className="p-4 pt-0">
                    <p className="text-gray-700 text-justify">{children}</p>
                </div>
            )}
        </div>
    );
};
export const Faq = () => {
    return (
        <section className="p-8 flex w-full lg:min-h-screen justify-center relative">
            <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto" />
            <div className="h-full flex flex-col w-full max-w-screen-content z-10 mt-20 lg:mt-32">
                <div className="relative pb-4 mb-8 pl-4">
                    <h5 className="title text-4xl font-medium text-gray-800 lg:text-5xl title-underline-left">
                        Najczęściej zadawane pytania
                    </h5>
                </div>
                <div className="flex flex-col lg:flex-row w-full space-y-4 lg:space-y-0 lg:space-x-4">
                    <div className="flex flex-col w-full lg:w-1/2 space-y-4">
                        <Item title="Jak można zostać Wolontariuszem #tozJawor?">
                            Jak można zostać Wolontariuszem{' '}
                            <strong>#tozJawor?</strong> Jeśli chcesz zostać
                            wolontariuszem to po podpisaniu umowy
                            wolontariackiej może nam pomagać w bezpośredniej
                            opiece nad zwierzętami. Pamiętajmy, że wolontariusze
                            także umieszczają ogłoszenia o zwierzętach do
                            adopcji, robią im zdjęcia i pomagają sprzed
                            monitorów komputerów. Wystarczy mieć ukończone 16
                            lat i zgodę rodziców. Osoby poniżej 13. roku życia
                            mogą odwiedzać psy w przytulisku tylko i wyłącznie
                            pod opieką Rodziców.
                        </Item>
                        <Item title="Pytanie nr 2?">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque rem aperiam, eaque
                            ipsa quae.
                        </Item>
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2 space-y-4">
                        <Item title="PYtanie nr 3?">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque rem aperiam, eaque
                            ipsa quae.
                        </Item>
                        <Item title="Pytanie nr 4?">
                            Sed ut perspiciatis unde omnis iste natus error sit
                            voluptatem accusantium doloremque rem aperiam, eaque
                            ipsa quae.
                        </Item>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
