import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { IconBrandFacebook, IconBrandInstagram, IconBrandMessenger, IconMail, IconMapPin } from '@tabler/icons';

import { ReactComponent as FooterBL } from '../../assets/images/Footer_bl.svg';
import { ReactComponent as FooterTR } from '../../assets/images/Footer_tr.svg';
import { ReactComponent as Logo } from '../../assets/images/Logo_White.svg';
import NavLinksFooter from '../components/Footer/NavLinksFooter';

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
    const [heroSectionId, setHeroSectionId] = useState(null);
    const wrapper = useRef(null);

    useEffect(() => {
        const footerElements = wrapper.current.children;
        gsap.set([...footerElements], { autoAlpha: 0 });

        const tl = gsap.timeline({ default: { ease: 'Power3.InOut' } });

        tl.fromTo(
            footerElements,
            { y: '-=50' },
            { duration: 1.5, y: '+=50', autoAlpha: 1, stagger: 0.2 }
        );

        ScrollTrigger.create({
            trigger: footerElements,
            animation: tl,
            start: 'top 50%',
        });
    }, []);

    useEffect(() => {
        const heroSection = document.getElementById('heroSection');
        setHeroSectionId(heroSection);
    }, [heroSectionId]);

    const scrollToHeroSectionHandler = () => {
        if (heroSectionId) {
            gsap.to(window, {
                duration: 1,
                scrollTo: { y: heroSectionId },
                ease: 'power3.inOut',
            });
        }
    };

    return (
        <footer className="flex w-full justify-start lg:justify-center bg-footer-blue relative">
            <FooterTR className="hidden lg:flex absolute top-0 right-0" />
            <FooterBL className="hidden lg:flex absolute bottom-0 left-0 " />
            <div
                ref={wrapper}
                className="p-8 lg:p-0 flex flex-col items-start lg:items-center justify-around w-full max-w-screen-md z-10 mt-8 lg:mt-36 lg:mb-24 min-h-screen lg:min-h-0 lg:h-96"
            >
                <Logo
                    onClick={scrollToHeroSectionHandler}
                    className="cursor-pointer mb-8 lg:mb-0"
                />
                <div className="flex flex-col lg:flex-row lg:justify-around lg:items-center w-full space-y-8 lg:space-y-0">
                    <div className="flex items-center text-white">
                        <div className="p-3 mr-2 bg-footer-second-blue text-white rounded-full">
                            <IconMail size={24} stroke={1.5} />
                        </div>
                        <span className="title font-light">jawor@toz.pl</span>
                    </div>
                    <Link
                        to="\\facebook.com/tozjawor"
                        target="_blank"
                        className="flex items-center text-white"
                    >
                        <div className="p-3 mr-2 bg-footer-second-blue text-white rounded-full">
                            <IconBrandMessenger size={24} stroke={1.5} />
                        </div>
                        <span className="title font-light">
                            facebook.com/tozjawor
                        </span>
                    </Link>
                    <div className="flex items-center text-white">
                        <div className="p-3 mr-2 bg-footer-second-blue text-white rounded-full">
                            <IconMapPin size={24} stroke={1.5} />
                        </div>
                        <span className="title text-base font-light">
                            Słowackiego 32, 59-400 Jawor
                        </span>
                    </div>
                </div>
                <hr className="hidden lg:flex text-white h-2 w-5/6" />
                <NavLinksFooter />
                <small className="text-white mt-8 lg:mt-0">
                    Copyright &copy; 2021 TOZ Jawor. Wszelkie prawa zastrzeżone.
                </small>
                <div className="flex text-white mt-8 lg:mt-0">
                    <Link
                        to="\\facebook.com/tozjawor"
                        target="_blank"
                        className="mr-2"
                    >
                        <IconBrandFacebook size={24} stroke={1.5} />
                    </Link>
                    <Link to="\\instagram.com/tozjawor/?hl=pl" target="_blank">
                        <IconBrandInstagram size={24} stroke={1.5} />
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
