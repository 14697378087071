import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import ArticlesList from '../components/Articles/ArticlesList';
import * as actions from '../../store/actions/index';
import { ReactComponent as ArticlesTR } from '../../assets/images/Articles_tr.svg'; // Top Right
import { ReactComponent as ArticlesTL } from '../../assets/images/Articles_tl.svg'; //Top Left
import { ReactComponent as ArticlesBL } from '../../assets/images/Articles_bl.svg'; // Bottom Left
import { ReactComponent as ArticlesBR } from '../../assets/images/Articles_br.svg'; //Bottom Right

gsap.registerPlugin(ScrollTrigger);

const Articles = props => {
    const [ articlesListRefData, setArticlesListRefData ] = useState(null)
    const wrapper = useRef(null);
    const { articles, indexArticle } = props;

    useEffect(() => {
        indexArticle(1, 3);
    }, [indexArticle]);

    useEffect(() => {
        if (articlesListRefData) {
            const wrapperElements = wrapper.current.children;  
                 
            const [firstTitle, secondTitle, paragraph] = wrapperElements;

            gsap.set([firstTitle, secondTitle, paragraph, articlesListRefData.desktop, articlesListRefData.mobile], { autoAlpha: 0 });
            
            const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

            tl.fromTo(firstTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel')
            .fromTo(secondTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.2')
            .fromTo(paragraph, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.4')
            .fromTo(articlesListRefData.desktop, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.6')
            .fromTo(articlesListRefData.mobile, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.6')

            ScrollTrigger.create({
                trigger: wrapperElements,
                animation: tl,
                start: 'top 50%',
            })
        }

    }, [articlesListRefData])

    const articlesWrapperHandler = refData => {
        setArticlesListRefData(refData);
    }

    return (
        <section className="flex w-full min-h-screen justify-start lg:justify-center relative">
            <ArticlesTL className="hidden lg:flex absolute top-12 left-0" />
            <ArticlesTR className="hidden lg:flex absolute top-0 right-0 max-w-sm xl:max-w-max" />
            <ArticlesBL className="hidden lg:flex absolute bottom-0 left-0 " />
            <ArticlesBR className="hidden lg:flex absolute bottom-32 right-0" />
            <div
                ref={wrapper}
                className="min-h-screen flex flex-col items-start lg:items-center lg:justify-between w-full max-w-screen-content z-10"
            >
                <h1 className="title text-2xl font-medium text-main-orange uppercase mt-8 lg:mt-36 pb-4 pt-8 px-8 lg:px-0 lg:pt-0">
                    Co u nas słychać?
                </h1>
                <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl pb-6 px-8 lg:px-0 lg:pt-0">
                    Aktualności
                </h1>
                <p className="text-base lg:text-center leading-7 lg:leading-8 lg:w-2/5 pb-12 px-8 lg:px-0 lg:pt-0">
                    Bądź z nami na bieżąco i sprawdź co u nas słychać.
                </p>
                {articles.articles && (
                    <ArticlesList 
                        articles={articles.articles} 
                        articlesWrapperHandler={articlesWrapperHandler} 
                    />
                )}
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        articles: state.articles.articles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        indexArticle: (page, limit) =>
            dispatch(actions.paginatedIndexArticleAsync(page, limit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
