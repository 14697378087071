import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import HelpItems from '../components/Help/HelpItems';

gsap.registerPlugin(ScrollTrigger);

const Help = () => {
    const wrapper = useRef(null);

    useEffect(() => {
        const wrapperElements = wrapper.current.children;

        const [ firstTitle, secondTitle, paragraph ] = wrapperElements;

        gsap.set([firstTitle, secondTitle, paragraph], { autoAlpha: 0 });

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.fromTo(firstTitle, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 }, 'titleLabel')
          .fromTo(secondTitle, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 }, 'titleLabel+=0.2')
          .fromTo(paragraph, { y: '-=50' }, { duration: 1.5, y: '+=50', autoAlpha: 1 }, 'titleLabel+=0.4')

        ScrollTrigger.create({
            trigger: wrapperElements,
            animation: tl,
            start: 'top 50%',
        })
    }, []);

    return (
        <section id="Help" className="flex w-full min-h-screen justify-start lg:justify-center bg-third-gray relative">
            <div
                ref={wrapper}
                className="flex flex-col min-h-screen p-8 items-start lg:items-center lg:justify-between w-full max-w-screen-content z-10 overflow-hidden"
            >
                <h1 className="title text-2xl font-medium text-main-orange uppercase mt-8 lg:mt-36 pb-4">
                    Wesprzyj zwierzaki
                </h1>
                <h1 className="title text-4xl font-medium text-gray-800 lg:text-5xl pb-6">
                    Ty też możesz pomóc
                </h1>
                <p className="text-base lg:text-center leading-7 lg:leading-8 lg:w-2/5 pb-12">
                    Zobaczcie psiaki i kociaki, które teraz szukają domu! Jeżeli
                    chciałbyś zagwarantować, któremuś zwierzęciu kochający dom –
                    nie wahaj się! Przyjedź do nas i adoptuj.
                </p>
                <HelpItems />
            </div>
        </section>
    );
};

export default Help;
