import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import Navbar from './pages/Navbar';
import HeroSection from './pages/HeroSection';
import Pets from './pages/Pets';
import Articles from './pages/Articles';
import Help from './pages/Help';
import Adoptions from './pages/Adoptions';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Footer from './pages/Footer';

import PetsSubpage from '../subpages/pages/Pets';
import PetShow from '../subpages/pages/PetShow';
import ArticlesSubpage from '../subpages/pages/Articles';
import ArticleShow from '../subpages/pages/ArticleShow';
import HelpSubpage from '../subpages/pages/Help';
import AdoptionsSubpage from '../subpages/pages/Adoptions';
import FaqSubpage from '../subpages/pages/Faq';

import './pages/RubikFont.css';

// const PetsSubpage = lazy(() => import('../subpages/pages/Pets'));
// const PetShow = lazy(() => import('../subpages/pages/PetShow'));
// const ArticlesSubpage = lazy(() => import('../subpages/pages/Articles'));
// const ArticleShow = lazy(() => import('../subpages/pages/ArticleShow'));
// const HelpSubpage = lazy(() => import('../subpages/pages/Help'));
// const AdoptionsSubpage = lazy(() => import('../subpages/pages/Adoptions'));
// const FaqSubpage = lazy(() => import('../subpages/pages/Faq'));

const Home = () => {
    let { path } = useRouteMatch();
    return (
        <React.Fragment>
            <Navbar />
            <main className="min-h-screen">
                <Switch>
                    <Route path="/" exact>
                        <HeroSection />
                        <Pets />
                        <Articles />
                        <Help />
                        <Adoptions />
                        <AboutUs />
                        <Contact />
                        <Footer />
                    </Route>
                    <Route path={`${path}pets`} component={PetsSubpage} exact />
                    <Route path={`${path}pets/:petId`} component={PetShow} />
                    <Route
                        path={`${path}articles`}
                        component={ArticlesSubpage}
                        exact
                    />
                    <Route
                        path={`${path}articles/:articleId`}
                        component={ArticleShow}
                    />
                    <Route path={`${path}help`} component={HelpSubpage} />
                    <Route
                        path={`${path}adoptions`}
                        component={AdoptionsSubpage}
                    />
                    <Route path={`${path}faq`} component={FaqSubpage} />
                    <Redirect to="/" />
                </Switch>
            </main>
        </React.Fragment>
    );
};

export default Home;
