import * as actionTypes from './action_types';
import axios from 'axios';

export const clearMessageErrorsArticle = () => {
    return {
        type: actionTypes.CLEAR_MESSAGE_ERRORS_ARTICLE,
    };
};

export const setArticleContent = content => {
    return {
        type: actionTypes.CONTENT_ARTICLE,
        content,
    };
};

export const startArticle = () => {
    return {
        type: actionTypes.START_ARTICLE,
    };
};

export const failArticle = errors => {
    return {
        type: actionTypes.FAIL_ARTICLE,
        errors,
    };
};

export const successArticle = message => {
    return {
        type: actionTypes.SUCCESS_ARTICLE,
        message,
    };
};

export const clearArticle = () => {
    return {
        type: actionTypes.CLEAR_ARTICLE,
    };
};

export const indexArticle = articles => {
    return {
        type: actionTypes.INDEX_ARTICLE,
        articles,
    };
};

export const showArticle = article => {
    return {
        type: actionTypes.SHOW_ARTICLE,
        article,
    };
};

export const destroyArticle = articleId => {
    return {
        type: actionTypes.DESTROY_ARTICLE,
        articleId,
    };
};

export const indexArticleAsync = () => {
    return async dispatch => {
        try {
            dispatch(startArticle());
            const response = await axios.get('/api/article');
            dispatch(indexArticle(response.data.articles));
        } catch (error) {
            dispatch(failArticle(error.response.data.errors));
        }
    };
};

export const paginatedIndexArticleAsync = (page, limit) => {
    return async dispatch => {
        try {
            dispatch(startArticle());
            const response = await axios.get(
                `/api/article/paginated?page=${page}&limit=${limit}`
            );
            dispatch(indexArticle(response.data.articles));
        } catch (error) {
            dispatch(failArticle(error.response.data.errors));
        }
    };
};

export const searchArticleAsync = titleInclude => {
    return async dispatch => {
        try {
            dispatch(startArticle());
            const response = await axios.get(
                `/api/article/search?titleInclude=${titleInclude}`
            );
            dispatch(indexArticle(response.data.articles));
        } catch (error) {
            dispatch(failArticle(error.response.data.errors));
        }
    };
};

export const storeArticleAsync = (formData, token) => {
    return async dispatch => {
        try {
            dispatch(startArticle());
            const response = await axios.post('/api/article', formData, {
                headers: {
                    Accept: 'appliArticleion/json',
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
            });
            dispatch(successArticle(response.data.message));
        } catch (error) {
            dispatch(failArticle(error.response.data.errors));
        }
    };
};

export const showArticleAsync = articleId => {
    return async dispatch => {
        try {
            // dispatch(clearArticle());
            dispatch(startArticle());
            const response = await axios.get(`/api/article/${articleId}`);
            dispatch(showArticle(response.data.article));
        } catch (error) {
            dispatch(failArticle(error.response.data.errors));
        }
    };
};

export const updateArticleAsync = (formData, token, articleId) => {
    return async dispatch => {
        try {
            dispatch(startArticle());
            const response = await axios.patch(
                `/api/article/${articleId}`,
                formData,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(successArticle(response.data.message));
        } catch (error) {
            dispatch(failArticle(error.response.data.errors));
        }
    };
};

export const destroyArticleAsync = (articleId, token) => {
    return async dispatch => {
        try {
            dispatch(startArticle());
            const response = await axios.delete(`/api/article/${articleId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(destroyArticle(articleId));
            dispatch(successArticle(response.data.message));
        } catch (error) {
            dispatch(failArticle(error.response.data.errors));
        }
    };
};
