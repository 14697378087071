import React from 'react';

import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg';
import { ReactComponent as HelpBR } from '../../assets/images/Help_br.svg';
import { ReactComponent as HelpBL } from '../../assets/images/Help_bl.svg';
import { ReactComponent as HelpTR } from '../../assets/images/Help_tr.svg';
import { ReactComponent as Tax } from '../../assets/images/Tax.svg';
import { ReactComponent as Transfer } from '../../assets/images/Transfer.svg';
import { ReactComponent as Items } from '../../assets/images/Items.svg';
import { ReactComponent as Volunteering } from '../../assets/images/Volunteering.svg';

import '../../shared/css/UnderlineTitle.css';

const Help = () => {
    return (
        <>
            <section className="flex w-full justify-center relative p-8">
                <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto" />
                <HelpTR className="hidden lg:block absolute top-1/3 right-0" />
                <HelpBL className="hidden lg:block absolute left-0 bottom-20" />
                <HelpBR className="hidden lg:block absolute right-0 bottom-0" />
                <div className="h-full w-full max-w-screen-content z-10 mt-20 lg:mt-32">
                    <div className="space-y-2 md:space-y-4 mb-8">
                        <div className="relative pl-4">
                            <h5 className="title text-4xl font-medium text-gray-800 lg:text-5xl title-underline-left">
                                Pomoc
                            </h5>
                        </div>
                        <p className="text-lg leading-7 text-gray-500 pl-4 pb-8">
                            Sprawdź jak możesz nam pomóc.
                        </p>
                        <section className="flex flex-col lg:flex-row w-full justify-start relative z-10 mt-16 pb-10">
                            <div className="space-y-2 md:space-y-5 mb-8 lg:mr-12">
                                <Tax />
                            </div>
                            <ul className="items-center text-gray-800 lg:w-5/12">
                                <h5 className="title text-2xl font-medium text-gray-800 mb-6">
                                    1% Twojego podatku
                                </h5>
                                <li className="mb-6">
                                    Pieniądze, które otrzymujemy z Państwa 1%,
                                    przeznaczamy na: Dobrej jakości karmy,
                                    wizyty weterynaryjne, leki oraz materiały
                                    medyczne, środki pielęgnacyjne.
                                </li>
                                <li className="font-bold mb-1">
                                    TOZ ODDZIAŁ W JAWORZE
                                </li>
                                <li className="mb-1">
                                    KRS{' '}
                                    <span className="text-gray-800 font-bold">
                                        0000154454
                                    </span>
                                </li>
                                <li className="mb-1">
                                    Cel szczegółowy:{' '}
                                    <span className="text-gray-800 font-semibold">
                                        ODDZIAŁ W JAWORZE
                                    </span>
                                </li>
                            </ul>
                        </section>
                        <section className="flex flex-col lg:flex-row w-full justify-start relative z-10 pb-10">
                            <div className="space-y-2 md:space-y-5 mb-8 lg:mr-12">
                                <Transfer />
                            </div>
                            <ul className="items-center text-gray-800">
                                <h5 className="title text-2xl font-medium text-gray-800 mb-6">
                                    Przelew
                                </h5>
                                <li className="font-bold">
                                    TOZ w Polsce Oddział w Jaworze
                                </li>
                                <li>59-400 Jawor</li>
                                <li className="mb-6">
                                    BGŻ{' '}
                                    <span className="font-bold">
                                        68 2030 0045 1110 0000 0415 6010
                                    </span>
                                </li>
                                <li className="font-bold">
                                    Dla wpłat zagranicznych
                                </li>
                                <li>BGŻ PL 68 2030 0045 1110 0000 0415 6010</li>
                                <li>
                                    <span className="font-bold uppercase">
                                        IBAN:
                                    </span>{' '}
                                    PL6820300045111000000415 6010
                                </li>
                                <li>
                                    <span className="font-bold uppercase">
                                        SWIFT:
                                    </span>{' '}
                                    PPABPLPK
                                </li>
                                <li>
                                    <span className="font-bold uppercase">
                                        Tytuł:
                                    </span>{' '}
                                    darowizna na cele statutowe
                                </li>
                            </ul>
                        </section>
                        <section className="flex flex-col lg:flex-row w-full justify-start relative z-10 pb-10">
                            <div className="space-y-2 md:space-y-5 mb-8 lg:mr-12">
                                <Items />
                            </div>
                            <ul className="items-center text-gray-800 lg:w-5/12">
                                <h5 className="title text-2xl font-medium text-gray-800 mb-6">
                                    Pomoc rzeczowa
                                </h5>
                                <li className="mb-6">
                                    Karma dla psów i kotów, obroże, szelki,
                                    smycze dla psów o różnej wielkości. Ręczniki
                                    oraz koce. Zabawki dla psów oraz kotów.
                                </li>
                                <li>
                                    Przedmioty przeznaczone do pielęgnacji
                                    zwierząt np. szczotki, grzebienie.
                                </li>
                            </ul>
                        </section>
                        <section className="flex flex-col lg:flex-row w-full justify-start relative z-10">
                            <div className="space-y-2 md:space-y-5 mb-8 lg:mr-12">
                                <Volunteering />
                            </div>
                            <ul className="flex flex-col justify-around text-gray-800 lg:w-5/12">
                                <h5 className="title text-2xl font-medium text-gray-800 mb-6">
                                    Wolontariat
                                </h5>
                                <li>
                                    Jeśli chcesz zostać wolontariuszem to po
                                    podpisaniu umowy wolontariackiej, będziesz
                                    mógł nam pomagać w bezpośredniej opiece nad
                                    zwierzętami.
                                </li>
                                <li>
                                    Pamiętajmy, że wolontariusze także
                                    umieszczają ogłoszenia o zwierzętach do
                                    adopcji, robią im zdjęcia i pomagają sprzed
                                    monitorów komputerów. Wystarczy mieć
                                    ukończone 16 lat i zgodę rodziców.
                                </li>
                                <li>
                                    Osoby poniżej 13. roku życia mogą odwiedzać
                                    psy w przytulisku tylko i wyłącznie pod
                                    opieką Rodziców.
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Help;
