export const dateFormat = data => {
    const months = [
        'styczeń',
        'luty',
        'marzec',
        'kwiecień',
        'maj',
        'czerwiec',
        'lipiec',
        'sierpień',
        'wrzesień',
        'październik',
        'listopad',
        'grudzień',
    ];

    let day = data.getDate().toString();
    
    if (day.length < 2) {
        day = day.padStart(2, '0')
    }

    const month = months[data.getMonth()];
    const year = data.getFullYear();

    const formattedData = `${day} ${month} ${year}`;

    return formattedData;
};
