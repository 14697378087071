import * as actionTypes from './action_types';
import axios from 'axios';

export const clearMessageErrorsCat = () => {
    return {
        type: actionTypes.CLEAR_MESSAGE_ERRORS_CAT,
    };
};

export const startCat = () => {
    return {
        type: actionTypes.START_CAT,
    };
};

export const failCat = errors => {
    return {
        type: actionTypes.FAIL_CAT,
        errors,
    };
};

export const successCat = message => {
    return {
        type: actionTypes.SUCCESS_CAT,
        message,
    };
};

export const clearCat = () => {
    return {
        type: actionTypes.CLEAR_CAT
    }
}


export const indexCat = cats => {
    return {
        type: actionTypes.INDEX_CAT,
        cats,
    };
};

export const showCat = cat => {
    return {
        type: actionTypes.SHOW_CAT,
        cat
    }
}

export const counterCat = amount => {
    return {
        type: actionTypes.COUNTER_CAT,
        amount,
    };
};

export const destroyCat = catId => {
    return {
        type: actionTypes.DESTROY_CAT,
        catId,
    };
};

export const indexCatAsync = () => {
    return async dispatch => {
        try {
            dispatch(startCat());
            const response = await axios.get('/api/cat');
            dispatch(indexCat(response.data.cats));
        } catch (error) {
            dispatch(failCat(error.response.data.errors));
        }
    };
};

export const storeCatAsync = (formData, token) => {
    return async dispatch => {
        try {
            dispatch(startCat());
            const response = await axios.post('/api/cat', formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                },
            });
            dispatch(successCat(response.data.message));
        } catch (error) {
            dispatch(failCat(error.response.data.errors));
        }
    };
};

export const showCatAsync = (catId) => {
    return async dispatch => {
        try {
            dispatch(startCat());
            const response = await axios.get(`/api/cat/${catId}`);
            dispatch(showCat(response.data.cat));
        } catch (error) {
            dispatch(failCat(error.response.data.errors));
        }
    }
}

export const updateCatAsync = (formData, token, catId) => {
    return async dispatch => {
        try {
            dispatch(startCat());
            const response = await axios.patch(`/api/cat/${catId}`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(successCat(response.data.message));
        } catch (error) {
            dispatch(failCat(error.response.data.errors));
        }
    };
};

export const destroyCatAsync = (catId, token) => {
    return async dispatch => {
        try {
            dispatch(startCat());
            const response = await axios.delete(`/api/cat/${catId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            dispatch(destroyCat(catId));
            dispatch(successCat(response.data.message));
        } catch (error) {
            dispatch(failCat(error.response.data.errors));
        }
    };
};

export const counterCatAsync = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`/api/cat/counter`);
            dispatch(counterCat(response.data.catsCounter));
        } catch (error) {
            dispatch(failCat(error.response.data.errors));
        }
    };
};

