import React from 'react';

const Pagination = props => {
    const { prev, next, totalItems, index, range, name, className } = props;

    const nextHandler = next => {
        const page = next.page;
        const limit = next.limit;
        index(page, limit);
    };

    const prevHandler = prev => {
        const page = prev.page;
        const limit = prev.limit;
        index(page, limit);
    };

    return (
        <div
            className={`${className} px-4 py-3 flex items-center justify-between border-t border-gray-200 lg:px-6 mb-8`}
        >
            <div className="flex-1 flex justify-between lg:hidden">
                <button
                    onClick={() => prevHandler(prev)}
                    disabled={!prev && true}
                    className={`${
                        !prev && 'disabled:opacity-50 cursor-not-allowed'
                    } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500`}
                >
                    Poprzednia
                </button>
                <button
                    onClick={() => nextHandler(next)}
                    disabled={!next && true}
                    className={`${
                        !next && 'disabled:opacity-50 cursor-not-allowed'
                    } ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500`}
                >
                    Następna
                </button>
            </div>
            <div className="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
                <div>
                    {range && totalItems && (
                        <p className="text-sm text-gray-700">
                            <span className="font-semibold">{`Wyświetlono od ${
                                range.from
                            } do ${
                                range.to
                            } z ${totalItems} ${name}.`}</span>
                        </p>
                    )}
                </div>
                <div>
                    <nav
                        className="relative z-0 inline-flex shadow-sm -space-x-px"
                        aria-label="Pagination"
                    >
                        <button
                            onClick={() =>
                                prevHandler({ page: 1, limit: prev.limit })
                            }
                            disabled={!prev && true}
                            className={`${
                                !prev &&
                                'disabled:opacity-50 cursor-not-allowed'
                            } relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                        >
                            <span>Pierwsza</span>
                        </button>
                        <button
                            onClick={() => prevHandler(prev)}
                            disabled={!prev && true}
                            className={`${
                                !prev &&
                                'disabled:opacity-50 cursor-not-allowed'
                            } relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                        >
                            <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span>Poprzednia</span>
                        </button>
                        {/* <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                            1
                        </button>
                        <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                            ...
                        </span>
                        <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                            10
                        </button> */}
                        <button
                            onClick={() => nextHandler(next)}
                            disabled={!next && true}
                            className={`${
                                !next &&
                                'disabled:opacity-50 cursor-not-allowed'
                            } relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                        >
                            <span>Następna</span>
                            <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() =>
                                nextHandler({
                                    page: Math.ceil(totalItems / next.limit),
                                    limit: next.limit,
                                })
                            }
                            disabled={!next && true}
                            className={`${
                                !next &&
                                'disabled:opacity-50 cursor-not-allowed'
                            } relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                        >
                            <span>Ostatnia</span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
