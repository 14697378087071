import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavLinks.css';

const NavbarItems = props => {
    switch (props.type) {
        case 'desktop':
            return (
                <ul className="flex space-x-1 xl:space-x-10 text-base uppercase">
                    <li className="wrapper -m-3 p-3 flex items-center">
                        <NavLink
                            to="/pets"
                            className="link"
                            activeClassName={`active--desktop`}
                        >
                            Nasze Zwierzęta
                        </NavLink>
                    </li>
                    <li className="wrapper -m-3 p-3 flex items-center">
                        <NavLink
                            to="/articles"
                            className="link"
                            activeClassName={`active--desktop`}
                        >
                            Aktualności
                        </NavLink>
                    </li>
                    <li className="wrapper -m-3 p-3 flex items-center">
                        <NavLink
                            to="/help"
                            className="help"
                            activeClassName={`active--help`}
                        >
                            Pomoc
                        </NavLink>
                    </li>
                    <li className="wrapper -m-3 p-3 flex items-center">
                        <NavLink
                            to="/adoptions"
                            className="link"
                            activeClassName={`active--desktop`}
                        >
                            Zaadoptowane
                        </NavLink>
                    </li>
                    <li className="wrapper -m-3 p-3 flex items-center">
                        <NavLink
                            to="/faq"
                            className="faq"
                            activeClassName={`active--faq`}
                        >
                            Faq
                        </NavLink>
                    </li>
                </ul>
            );
        case 'mobile':
            return (
                <nav className="grid gap-y-8">
                    <NavLink
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        activeClassName="bg-gray-100"
                        to="/pets"
                        onClick={() => props.mobileNavbarStatusHandler()}
                    >
                        Nasze Zwierzęta
                    </NavLink>
                    <NavLink
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        activeClassName="bg-gray-100"
                        to="/articles"
                        onClick={() => props.mobileNavbarStatusHandler()}
                    >
                        Aktualności
                    </NavLink>
                    <NavLink
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        activeClassName="bg-gray-100"
                        to="/help"
                        onClick={() => props.mobileNavbarStatusHandler()}
                    >
                        Pomoc
                    </NavLink>
                    <NavLink
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        activeClassName="bg-gray-100"
                        to="/adoptions"
                        onClick={() => props.mobileNavbarStatusHandler()}
                    >
                        Zaadoptowane
                    </NavLink>
                    <NavLink
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        activeClassName="bg-gray-100"
                        to="/faq"
                        onClick={() => props.mobileNavbarStatusHandler()}
                    >
                        FAQ
                    </NavLink>
                </nav>
            );
        default:
            break;
    }
};

export default NavbarItems;
