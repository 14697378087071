import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../shared/components/ui/Button';

const AdoptionItem = props => {
    return (
        <div className="flex flex-col w-full h-full mb-8 lg:even:mx-8">
            <Link
                to="/adoptions"
                className="rounded-lg h-96 lg:h-5/6 overflow-hidden"
            >
                <img
                    className="w-full h-full max-h-112 object-cover rounded-lg transform hover:scale-105 transition-transform duration-300 ease-in-out"
                    src={process.env.REACT_APP_API_URL + props.image}
                    alt={props.name + ' & ' + props.owner}
                />
            </Link>
            <div className="flex justify-between items-center mt-4">
                <div className="flex flex-col">
                    <h5 className="title font-medium text-2xl">{`${props.name} & ${props.owner}`}</h5>
                    <div className="flex items-center">
                        {/* {props.sex === 'Pies' ? (
                            <GiMale />
                        ) : props.sex === 'Kocur' ? (
                            <GiMale />
                        ) : (
                            <GiFemale />
                        )} */}
                        <span className="title font-light text-lg">
                            {`${props.sex} / ${props.age}`}
                        </span>
                    </div>
                </div>
                <div className="relative">
                    <Link to="/adoptions">
                        <Button
                            element={
                                props.index === 1
                                    ? 'lp-filled-purple'
                                    : 'lp-filled-orange'
                            }
                            className="relative p-4"
                        >
                            <span className="w-full">Zobacz Więcej</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AdoptionItem;
