import React from 'react';
import { Link } from 'react-router-dom';
import { IconCalendar } from '@tabler/icons'
import './ArticleItem.css';

import Button from '../../../shared/components/ui/Button';
import { truncateContent } from '../../../shared/utils/truncateContent';

const ArticleItem = props => {
    return (
        <>
            {/*Mobile */}
            <div className="lg:hidden rounded-lg h-full w-full relative article-wrapper">
                <img
                    className="w-full h-full max-h-120 min-h-112 object-cover rounded-lg article-image"
                    src={process.env.REACT_APP_API_URL + props.image}
                    alt={`${props.title}`}
                />
                <div className="flex flex-col justify-between article-content">
                    <span className="flex font-semibold text-base title-font text-white">
                        <IconCalendar size={24} stroke={1.5} className="mr-2" />
                        {props.date}
                    </span>
                    <div className="relative pb-1">
                        <h5
                            className={`title text-white text-3xl font-medium text-left ${
                                props.index === 1
                                    ? 'article-title-underline-purple'
                                    : 'article-title-underline-orange'
                            }`}
                        >
                            {props.title}
                        </h5>
                    </div>
                    <p className="text-sm text-white font-normal flex flex-col self-center text-justify article-paragraph">
                        {truncateContent(props.content, 450)}
                    </p>
                    <Link
                        to={`articles/${props.id}`}
                        className="w-full article-button self-center justify-self-center"
                    >
                        <Button
                            element={
                                props.index === 1
                                    ? 'lp-filled-purple'
                                    : 'lp-filled-orange'
                            }
                            className="relative p-4 mt-4 mb-8"
                        >
                            <span className="w-full">Czytaj Więcej</span>
                        </Button>
                    </Link>
                </div>
            </div>
            {/*Desktop*/}
            <div className="hidden lg:block rounded-lg h-full even:my-8 my-0 even:mx-4 w-1/3 relative article-wrapper overflow-hidden">
                <img
                    className="w-full h-full object-cover rounded-lg article-image"
                    src={process.env.REACT_APP_API_URL + props.image}
                    alt={`${props.title}`}
                />
                <div className="flex flex-col justify-around article-content">
                    <span className="flex items-center font-semibold text-base title-font text-white">
                        <IconCalendar size={22} stroke={1.5} className="mr-2" />
                        {props.date}
                    </span>
                    <div className="relative pb-2">
                        <h5
                            className={`title text-white text-2xl font-medium ${
                                props.index === 1
                                    ? 'article-title-underline-purple'
                                    : 'article-title-underline-orange'
                            }`}
                        >
                            {props.title}
                        </h5>
                    </div>
                    <p className="text-sm text-white font-medium flex flex-col self-center text-justify article-paragraph">
                        {truncateContent(props.content, 400)}
                    </p>
                    <Link
                        to={`articles/${props.id}`}
                        className="w-full article-button self-center justify-self-center"
                    >
                        <Button
                            element={
                                props.index === 1
                                    ? 'lp-filled-purple'
                                    : 'lp-filled-orange'
                            }
                            className="relative p-4 mt-4"
                        >
                            <span className="w-full">Czytaj Więcej</span>
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ArticleItem;
