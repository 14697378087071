/* User Authorization */

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';

/* Dogs */

export const START_DOG = 'START_DOG';
export const FAIL_DOG = 'FAIL_DOG';
export const SUCCESS_DOG = 'SUCCESS_DOG';
export const CLEAR_DOG = 'CLEAR_DOG';
export const INDEX_DOG = 'INDEX_DOG';
export const SHOW_DOG = 'SHOW_DOG';
export const DESTROY_DOG = 'DESTROY_DOG';
export const COUNTER_DOG = 'COUNTER_DOG';
export const CLEAR_MESSAGE_ERRORS_DOG = 'CLEAR_MESSAGE_ERRORS_DOG';

/* Cats */

export const START_CAT = 'START_CAT';
export const FAIL_CAT = 'FAIL_CAT';
export const SUCCESS_CAT = 'SUCCESS_CAT';
export const CLEAR_CAT = 'CLEAR_CAT';
export const INDEX_CAT = 'INDEX_CAT';
export const SHOW_CAT = 'SHOW_CAT';
export const DESTROY_CAT = 'DESTROY_CAT';
export const COUNTER_CAT = 'COUNTER_CAT';
export const CLEAR_MESSAGE_ERRORS_CAT = 'CLEAR_MESSAGE_ERRORS_CAT';

/* Articles */

export const START_ARTICLE = 'START_ARTICLE';
export const FAIL_ARTICLE = 'FAIL_ARTICLE';
export const SUCCESS_ARTICLE = 'SUCCESS_ARTICLE';
export const CLEAR_ARTICLE = 'CLEAR_ARTICLE';
export const INDEX_ARTICLE = 'INDEX_ARTICLE';
export const SHOW_ARTICLE = 'SHOW_ARTICLE';
export const DESTROY_ARTICLE = 'DESTROY_ARTICLE';
export const CONTENT_ARTICLE = 'CONTENT_ARTICLE';
export const CLEAR_MESSAGE_ERRORS_ARTICLE = 'CLEAR_MESSAGE_ERRORS_ARTICLE';

/* Adoptions */

export const START_ADOPTION = 'START_ADOPTION';
export const FAIL_ADOPTION = 'FAIL_ADOPTION';
export const SUCCESS_ADOPTION = 'SUCCESS_ADOPTION';
export const CLEAR_ADOPTION = 'CLEAR_ADOPTION';
export const INDEX_ADOPTION = 'INDEX_ADOPTION';
export const SHOW_ADOPTION = 'SHOW_ADOPTION';
export const DESTROY_ADOPTION = 'DESTROY_ADOPTION';
export const CLEAR_MESSAGE_ERRORS_ADOPTION = 'CLEAR_MESSAGE_ERRORS_ADOPTION';

/* Dark mode */

export const STORE_MODE = "SHOW_MODE";

/* Mail */

export const SEND_MAIL = "SEND_MAIL";
export const SUCCESS_MAIL = "SUCCESS_MAIL";
export const START_MAIL = "START_MAIL";

/* Others */

export const IS_DOGS = "IS_DOGS";



