import React, { useRef, useEffect } from 'react';

import AdoptionItem from './AdoptionItem';

const AdoptionsItems = props => {
    const mobileWrapper = useRef(null);
    const desktopWrapper = useRef(null);
    const { adoptions, adoptionsWrapperHandler } = props;

    useEffect(() => {
        const mobileWrapperElements = mobileWrapper.current.children;
        const desktopWrapperElements = desktopWrapper.current.children;
        adoptionsWrapperHandler({
            mobile: mobileWrapperElements,
            desktop: desktopWrapperElements
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {/* Mobile */}
            <div
                ref={mobileWrapper}
                className="lg:hidden flex flex-col h-full w-full lg:mb-28"
            >
                {adoptions.map((adoption, index) => (
                    <AdoptionItem
                        key={adoption._id}
                        id={adoption._id}
                        image={adoption.image}
                        name={adoption.name}
                        sex={adoption.sex}
                        age={adoption.age}
                        owner={adoption.owner}
                        index={index}
                    />
                ))}
            </div>
            {/* Desktop */}
            <div
                ref={desktopWrapper}
                className="hidden lg:flex items-center justify-between h-full w-full lg:mb-28 even:px-8"
            >
                {adoptions.map((adoption, index) => (
                    <AdoptionItem
                        key={adoption._id}
                        id={adoption._id}
                        image={adoption.image}
                        name={adoption.name}
                        sex={adoption.sex}
                        age={adoption.age}
                        owner={adoption.owner}
                        index={index}
                    />
                ))}
            </div>
        </>
    );
};

export default AdoptionsItems;
