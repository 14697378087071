import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import Button from '../../shared/components/ui/Button';
import Dog from '../../assets/images/Hero_Section_Dog.png';
import { ReactComponent as HeroTL } from '../../assets/images/Hero_tl.svg'; //HeroTL mean Hero Top Left
import { ReactComponent as HeroMR } from '../../assets/images/Hero_mr.svg'; //HeroMR mean Hero Middle Right
import './RubikFont.css';

gsap.registerPlugin(ScrollToPlugin);

const HeroSection = () => {
    const [aboutUsId, setAboutUsId] = useState(null);
    const [helpId, setHelpId] = useState(null);
    const wrapper = useRef(null);

    useEffect(() => {
        const wrapperElements = wrapper.current.children;

        const [ firstTitle, secondTitle, paragraph, buttons, dogImage ] = wrapperElements;

        gsap.set([firstTitle, secondTitle, paragraph, buttons, dogImage], { autoAlpha: 0 });

        const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

        tl.fromTo(firstTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel')
          .fromTo(dogImage, { x: '+=100' }, { duration: 2.1, x: '-=100', autoAlpha: 1}, 'titleLabel')
          .fromTo(secondTitle, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.2')
          .fromTo(paragraph, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.4')
          .fromTo(buttons, { y: '-=50' }, { duration: 1.5, y: 0, autoAlpha: 1 }, 'titleLabel+=0.6')
    }, []);

    useEffect(() => {
        const aboutUsId = document.getElementById('AboutUs');
        const helpId = document.getElementById('Help');
        setAboutUsId(aboutUsId);
        setHelpId(helpId)
    }, [aboutUsId, helpId])

    const scrollToAboutUsHandler = () => {
        if (aboutUsId) {
            gsap.to(window, {duration: 1, scrollTo: { y: aboutUsId }, ease: 'power3.inOut' })
        }
    }

    const scrollToHelpHandler = () => {
        if (helpId) {
            gsap.to(window, {duration: 1, scrollTo: { y: helpId }, ease: 'power3.inOut' })
        }
    }

    return (
        <section
            id="heroSection"
            className="p-8 flex w-full lg:h-screen lg:min-h-screen justify-center relative overflow-hidden"
        >
            <HeroTL className="absolute w-7/12 h-auto top-0 left-0 lg:w-auto" />
            <HeroMR className="hidden lg:block absolute top-36 right-0" />
            <div   
                className="h-full flex flex-col w-full max-w-screen-content z-10"
            >
                <div ref={wrapper} className="h-4/6 flex flex-col justify-center mt-20 lg:mt-32 lg:w-4/12 xl:w-5/12 2xl:w-5/12 3xl:w-6/12">
                    <h1 className="title text-4xl font-medium lg:text-5xl py-4">
                        Pomagamy zwierzętom
                    </h1>
                    <p className="text-base leading-7 lg:leading-8 py-8">
                        Od 2008 roku, nieprzerwanie, pomagamy bezdomnym,
                        porzuconym i skrzywdzonym zwierzętom. Każdego dnia
                        staramy się zapewnić im jak najlepsze warunki do życia,
                        poczucie bezpieczeństwa i w razie potrzeby umożliwić
                        pomoc weterynaryjną.
                    </p>
                    <p className="text-base leading-7 lg:leading-8 pb-6">
                        Uratuj bezdomne życia. Wolontariusze z przytuliska
                        proszą o wsparcie podopiecznych.
                    </p>
                    <div className="flex flex-col items-center lg:items-start xl:flex-row pb-6">
                        <Button
                            onClick={scrollToAboutUsHandler}
                            element="lp-filled-purple"
                            className="relative flex-grow-0 px-6 lg:px-2 py-4 w-5/6 lg:w-6/6 xl:w-3/6 2xl:w-2/6"
                        >
                            Dowiedz się więcej
                        </Button>
                        <Button
                            onClick={scrollToHelpHandler}
                            element="lp-filled-orange"
                            className="relative flex-grow-0 w-5/6 px-6 py-4 mt-2 xl:mt-0 xl:ml-4 lg:w-6/6 xl:w-4/6 2xl:w-3/6"
                        >
                            Wesprzyj bezdomne zwierzęta
                        </Button>
                    </div>
                    <img
                        className={`hidden lg:block right-0 bottom-0 absolute
                            w-10/12 lg:w-8/12 xl:w-7/12 2xl:w-7/12 2xl:max-w-screen-lg`}
                        src={Dog}
                        alt="Pies"
                    />
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
