import React, { useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { IconBrandFacebook, IconBrandInstagram } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Button from '../../shared/components/ui/Button';
import { ReactComponent as Logo } from '../../assets/images/Logo_Purple.svg';
import NavLinks from '../components/Navbar/NavLinks';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const Navbar = () => {
    const [mobileIsOpen, setMobileIsOpen] = useState(false);
    const navbarWrapper = useRef(null);

    useEffect(() => {
        const element = navbarWrapper.current;

        const heroSection = document.getElementById('heroSection');

        gsap.fromTo(
            element,
            { backgroundColor: 'transparent' },
            {
                backgroundColor: 'white',
                boxShadow: '0px 5px 23px -10px rgba(0,0,0,0.1)',
                duration: 0.5,
                ease: 'easeInOut',
                scrollTrigger: {
                    trigger: heroSection,
                    start: 'top',
                    end: '15%',
                    scrub: 0.5,
                },
            }
        );
    }, []);

    const mobileNavbarStatusHandler = () => {
        setMobileIsOpen(false);
    };

    // const scrollToContactHandler = () => {
    //     const contactId = document.getElementById('Contact');
    //     gsap.to(window, {
    //         duration: 1,
    //         scrollTo: { y: contactId },
    //         ease: 'power3.inOut',
    //     });
    //     setMobileIsOpen(false);
    // };

    return (
        <div
            id="navbar-wrapper"
            ref={navbarWrapper}
            className="fixed w-full bg-transparent z-20"
        >
            <div className="mx-auto px-8 xl:px-20">
                <div
                    id="desktop-wrapper"
                    className="flex justify-between items-center py-6 lg:justify-start md:space-x-10"
                >
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/">
                            <Logo className="h-8 w-auto sm:h-16" />
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 lg:hidden">
                        <button
                            onClick={() => setMobileIsOpen(!mobileIsOpen)}
                            type="button"
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        >
                            <span className="sr-only">Open menu</span>
                            {/* <!-- Heroicon name: menu --> */}
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <nav className="hidden lg:flex">
                        <NavLinks type="desktop" />
                    </nav>
                    <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
                        <Button
                            element="lp-border-purple"
                            className="mr-4 relative"
                        >
                            <Link
                                to="\\forms.gle/i6a6yLTEFDdX6ywo7"
                                target="_blank"
                            >
                                Adoptuj
                            </Link>
                        </Button>

                        <div className="flex justify-end">
                            <Link
                                to="\\facebook.com/tozjawor"
                                target="_blank"
                                className="p-2 mr-2 cursor-pointer hover:bg-main-purple text-main-purple hover:text-white rounded-full transition duration-200 ease-in-out"
                            >
                                <IconBrandFacebook size={24} stroke={1.5} />
                            </Link>
                            <Link
                                to="\\instagram.com/tozjawor/?hl=pl"
                                target="_blank"
                                className="p-2 cursor-pointer hover:bg-main-purple text-main-purple hover:text-white rounded-full transition duration-200 ease-in-out"
                            >
                                <IconBrandInstagram size={24} stroke={1.5} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Transition
                show={mobileIsOpen}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                {ref => (
                    <div
                        ref={ref}
                        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <Link to="/">
                                            <Logo className="h-8 w-auto" />
                                        </Link>
                                    </div>
                                    <div className="-mr-2">
                                        <button
                                            onClick={() =>
                                                setMobileIsOpen(!mobileIsOpen)
                                            }
                                            type="button"
                                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        >
                                            <span className="sr-only">
                                                Close menu
                                            </span>
                                            {/* <!-- Heroicon name: x --> */}
                                            <svg
                                                className="h-6 w-6"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <NavLinks
                                        type="mobile"
                                        mobileNavbarStatusHandler={
                                            mobileNavbarStatusHandler
                                        }
                                    />
                                </div>
                            </div>
                            <div className="py-6 px-6 space-y-6">
                                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                    <Button
                                        element="lp-border-purple"
                                        className="mr-8 relative w-9/12"
                                    >
                                        <Link
                                            to="\\forms.gle/i6a6yLTEFDdX6ywo7"
                                            target="_blank"
                                        >
                                            Adoptuj
                                        </Link>
                                    </Button>
                                    <div className="flex justify-end">
                                        <Link
                                            to="\\facebook.com/tozjawor"
                                            target="_blank"
                                            className="p-3 mr-2 cursor-pointer hover:bg-main-purple text-main-purple hover:text-white rounded-full transition duration-200 ease-in-out"
                                            onClick={() =>
                                                mobileNavbarStatusHandler()
                                            }
                                        >
                                            <IconBrandFacebook
                                                size={22}
                                                stroke={1.5}
                                            />
                                        </Link>
                                        <Link
                                            to="\\instagram.com/tozjawor/?hl=pl"
                                            target="_blank"
                                            className="p-3 cursor-pointer hover:bg-main-purple text-main-purple hover:text-white rounded-full transition duration-200 ease-in-out"
                                            onClick={() =>
                                                mobileNavbarStatusHandler()
                                            }
                                        >
                                            <IconBrandInstagram
                                                size={22}
                                                stroke={1.5}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Transition>
        </div>
    );
};

export default Navbar;
