import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PetItem from './PetItem';
import SpinnerPaw from '../../../shared/components/ui/SpinnerPaw';
import { ReactComponent as Dogs } from '../../../assets/images/Dogs.svg';
import { ReactComponent as Cats } from '../../../assets/images/Cats.svg';
import Button from '../../../shared/components/ui/Button';

const PetsList = props => {
    const { pets, dogLoading, catLoading, isDogs } = props;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let loading = dogLoading || catLoading;
        const timer = setTimeout(() => {
            if (isLoading !== loading) {
                setIsLoading(loading);
            }
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [isLoading, dogLoading, catLoading]);

    if (pets.length === 0 && isDogs === true) {
        return (
            <div className="flex items-center w-full h-full">
                <Dogs className="w-10/12 h-full self-center" />
                <div className="h-full flex flex-col justify-between space-y-8">
                    <h5 className="text-4xl mb-8 font-medium">
                        Cześć! W tej chwili nie mamy żadnych psów do adopcji.
                    </h5>
                    <p>
                        Od 2008 roku, nieprzerwanie, pomagamy bezdomnym,
                        porzuconym i skrzywdzonym zwierzętom. Każdego dnia
                        staramy się zapewnić im jak najlepsze warunki do życia,
                        poczucie bezpieczeństwa i w razie potrzeby umożliwić
                        pomoc weterynaryjną.
                    </p>
                    <p>
                        Uratuj bezdomne życia. Wolontariusze z przytuliska
                        proszą o wsparcie podopiecznych.
                    </p>
                    <Link to={`/help`} className="relative w-full flex justify-center lg:justify-start">
                        <Button
                            element={'lp-filled-orange'}
                            className="relative py-3 px-4"
                        >
                            <span className="w-full">
                                Wesprzyj bezdomne zwierzęta
                            </span>
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }

    if (pets.length === 0 && isDogs === false) {
        return (
            <div className="flex flex-col lg:flex-row lg:items-center w-full h-full">
                <Cats className="w-10/12 h-full self-center" />
                <div className="h-full flex flex-col justify-between space-y-8">
                    <h5 className="text-4xl mb-8 font-medium">
                        Cześć! W tej chwili nie mamy żadnych kotów do adopcji.
                    </h5>
                    <p>
                        Od 2008 roku, nieprzerwanie, pomagamy bezdomnym,
                        porzuconym i skrzywdzonym zwierzętom. Każdego dnia
                        staramy się zapewnić im jak najlepsze warunki do życia,
                        poczucie bezpieczeństwa i w razie potrzeby umożliwić
                        pomoc weterynaryjną.
                    </p>
                    <p>
                        Uratuj bezdomne życia. Wolontariusze z przytuliska
                        proszą o wsparcie podopiecznych.
                    </p>
                    <Link to={`/help`} className="relative w-full flex justify-center lg:justify-start">
                        <Button
                            element={'lp-filled-orange'}
                            className="relative py-3 px-4"
                        >
                            <span className="w-full">
                                Wesprzyj bezdomne zwierzęta
                            </span>
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <>
            {isLoading && (
                <SpinnerPaw className="absolute" loading={isLoading} />
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 h-full lg:mb-28">
                {pets.map((pet, index) => {
                    const setDate = new Date(pet.createdAt);
                    const date = setDate.toLocaleDateString();
                    return (
                        <PetItem
                            key={pet._id}
                            id={pet._id}
                            image={pet.image}
                            name={pet.name}
                            sex={pet.sex}
                            age={pet.age}
                            description={pet.description}
                            date={date}
                            index={index}
                        />
                    );
                })}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        dogLoading: state.dogs.loading,
        catLoading: state.cats.loading,
    };
};

export default connect(mapStateToProps)(PetsList);
