import * as actionsTypes from '../actions/action_types';
import { updateObject } from '../reducer_util';

const initialState = {
    errors: [],
    loading: false,
    message: null,
    adoptions: [],
    adoption: null,
};

const startAdoption = (state, action) => {
    return updateObject(state, { errors: null, loading: true, message: null });
};

const failAdoption = (state, action) => {
    return updateObject(state, { errors: action.errors, loading: false });
};

const successAdoption = (state, action) => {
    return updateObject(state, {
        message: action.message,
        errors: null,
        loading: false,
    });
};

const clearAdoption = (state, action) => {
    return updateObject(state, {
        adoption: null,
    });
};

const indexAdoption = (state, action) => {
    return updateObject(state, {
        adoptions: action.adoptions,
        errors: null,
        loading: false,
    });
};

const showAdoption = (state, action) => {
    return updateObject(state, {
        adoption: action.adoption,
        errors: null,
        loading: false,
    });
};

const destroyAdoption = (state, action) => {
    return updateObject(state, {
        adoptions: {
            ...state.adoptions,
            adoptions: state.adoptions.adoptions.filter(
                adoption => adoption._id !== action.adoptionId
            ),
        },
    });
};

const clearMessageErrorsAdoption = (state, action) => {
    return updateObject(state, {
        errors: null,
        message: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.START_ADOPTION:
            return startAdoption(state, action);
        case actionsTypes.FAIL_ADOPTION:
            return failAdoption(state, action);
        case actionsTypes.SUCCESS_ADOPTION:
            return successAdoption(state, action);
        case actionsTypes.CLEAR_ADOPTION:
            return clearAdoption(state, action);
        case actionsTypes.INDEX_ADOPTION:
            return indexAdoption(state, action);
        case actionsTypes.SHOW_ADOPTION:
            return showAdoption(state, action);
        case actionsTypes.DESTROY_ADOPTION:
            return destroyAdoption(state, action);
        case actionsTypes.CLEAR_MESSAGE_ERRORS_ADOPTION:
            return clearMessageErrorsAdoption(state, action);
        default:
            return state;
    }
};

export default reducer;
