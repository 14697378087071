import React, { useEffect, Suspense, lazy } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';

import Home from './home/Home';
import Logout from './auth/Logout';
import SpinnerPaw from './shared/components/ui/SpinnerPaw';
import * as actions from './store/actions/index';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Login = lazy(() => import('./auth/Login'));

const App = props => {
    const { isAuth, token, onAutoLogin } = props;

    useEffect(() => {
        if (token) {
            onAutoLogin();
        }
    }, [onAutoLogin, token]);

    useEffect(() => {
        const theme = localStorage.getItem('theme');
        if (theme === null) {
            localStorage.setItem('theme', 'light');
            document.querySelector('html').classList.remove('dark');
        }
        if (
            theme === 'dark' ||
            (!('theme' in localStorage) &&
                window.matchMedia('(prefers-color-scheme: dark)').matches)
        ) {
            document.querySelector('html').classList.add('dark');
        }
    }, []);

    let routes;

    if (isAuth) {
        routes = (
            <Switch>
                <Route path="/admin/login" component={Login} exact />
                {/* <Route path="/admin/register" component={Register} exact /> */}
                <Route path="/logout" component={Logout} exact />
                <Route path="/admin/dashboard" component={Dashboard} />
                <Route path="/">
                    <Home />
                </Route>
                <Redirect to="/" />
            </Switch>
        );
    } else {
        routes = (
            <Switch>
                <Route path="/admin/login" component={Login} exact />
                {/* <Route path="/admin/register" component={Register} exact /> */}
                <Route path="/logout" component={Logout} exact />
                <Route path="/" component={Home} />
                <Redirect to="/" />
            </Switch>
        );
    }

    return (
        <Router>
            <main>
                <Suspense fallback={<SpinnerPaw loading={true} />}>
                    {routes}
                </Suspense>
            </main>
        </Router>
    );
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuth,
        token: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAutoLogin: () => dispatch(actions.authCheckState()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
